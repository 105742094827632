import React, {DependencyList, useCallback} from "react";

type KeyPressHandler = React.KeyboardEventHandler<any>;

export const useOnEnterKeyPress = (eventHandler: KeyPressHandler, deps: DependencyList) => {
  return useCallback((event: React.KeyboardEvent<any>) => {
    if (event.key === 'Enter') {
      return eventHandler(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventHandler, ...deps]);
}