import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { Client } from "src/entities/Client";
import { SalonPoint, SalonServices, SalonWorker } from "src/entities/Salon";
import { Service } from "src/entities/Specialization";
import { RootState } from "..";
import { createSalon, createServicesSalon, deleteServiceSalon, getClientsByPointId, getServicesSalon, getStaffByPointId, getWorkersBySpecialization, loadSystemServices, updateSalon, updateServiceSalon } from "./thunks";
import { deleteSalon, updateSalonProfile } from "./salonSettingsThunk";

export type StatusType = 'create' | 'update'
export type StaffNav = 'home' | 'schedule'

type ClientSalon = {
  loading: boolean,
  disabled: boolean,
  editStatus: StatusType,
  clientsPoint: Client[],
  clientActive?: Client,
}

type StaffSalon = {
  loading: boolean,
  disabled: boolean,
  panel: StaffNav,
  editStatus: StatusType,
  staffPoint: SalonWorker[],
  staffActive?: SalonWorker
}

type ServicesSalon = {
  loading: boolean,
  disabled: boolean,
  editStatus: StatusType,
  // actualWorkers: SalonWorker[],
  systemServices: Service[],
  services: SalonServices[],
  activeService?: SalonServices,
  staff: SalonWorker[],
}

interface SalonState {
  loading: boolean,
  saving: boolean,
  viewSalons: 'list' | 'card',
  editStatus: StatusType,
  editPoint: SalonPoint,
  activeSalon?: SalonPoint,
  disabledEditSalon: boolean,
  clientSalon: ClientSalon,
  staffSalon: StaffSalon,
  servicesSalon: ServicesSalon
}

const localStorageSalon: SalonPoint | undefined = !!localStorage.salonPoint ? JSON.parse(localStorage.salonPoint) : undefined

const initialState: SalonState = {
  loading: false,
  saving: false,
  viewSalons: 'card',
  editStatus: 'create',
  disabledEditSalon: true,
  activeSalon: localStorageSalon,
  editPoint: {
    id: 0,
    name: 'Новый салон',
    phone: '',
    status: 'closed',
    address: null,
    salon: 0,
    administrator: null,
    days: [],
    scheduleType: 'daily'
  },
  clientSalon: {
    loading: false,
    disabled: true,
    editStatus: 'create',
    clientsPoint: [],
  },
  staffSalon: {
    panel: 'home',
    loading: false,
    disabled: true,
    editStatus: 'create',
    staffPoint: []
  },
  servicesSalon: {
    loading: false,
    disabled: true,
    editStatus: 'create',
    services: [],
    systemServices: [],
    staff: [],
  }
};

export const salonsSlice = createSlice({
  name: 'salons',
  initialState,
  reducers: {
    changeView: (state: SalonState, action: PayloadAction<'list' | 'card'>) => {
      state.viewSalons = action.payload
    },
    onChangePanelStaff: (state: SalonState, action: PayloadAction<StaffNav>) => {
      state.staffSalon.panel = action.payload
    },
    setPoint: (state: SalonState, action: PayloadAction<SalonPoint>) => {
      state.editStatus = 'update'
      state.editPoint = action.payload
    },
    setActiveSalon: (state: SalonState, action: PayloadAction<SalonPoint>) => {
      state.activeSalon = action.payload
    },
    createPoint: (state: SalonState, action: PayloadAction<SalonPoint>) => {
      state.editStatus = 'create'
      state.editPoint = action.payload
      state.disabledEditSalon = false
    },
    disabledSalonChange: (state: SalonState, action: PayloadAction<boolean>) => {
      state.disabledEditSalon = action.payload
    },

    setClient: (state: SalonState, action: PayloadAction<Client>) => {
      state.clientSalon.clientActive = action.payload
      state.clientSalon.editStatus = 'update'
    },
    createClient: (state: SalonState, action: PayloadAction<Client>) => {
      state.clientSalon.clientActive = action.payload
      state.clientSalon.editStatus = 'create'
      state.clientSalon.disabled = false
    },
    disabledClientChange: (state: SalonState, action: PayloadAction<boolean>) => {
      state.clientSalon.disabled = action.payload
    },

    setStaff: (state: SalonState, action: PayloadAction<SalonWorker>) => {
      state.staffSalon.staffActive = action.payload
      state.staffSalon.editStatus = 'update'
    },
    createStaff: (state: SalonState, action: PayloadAction<SalonWorker>) => {
      state.staffSalon.staffActive = action.payload
      state.staffSalon.editStatus = 'create'
      state.staffSalon.disabled = false
      state.staffSalon.panel = 'home'
    },
    disabledStaffChange: (state: SalonState, action: PayloadAction<boolean>) => {
      state.staffSalon.disabled = action.payload
    },

    disabledServiceChange: (state: SalonState, action: PayloadAction<boolean>) => {
      state.servicesSalon.disabled = action.payload
    },
    setService: (state: SalonState, action: PayloadAction<SalonServices>) => {
      state.servicesSalon.activeService = action.payload
      state.servicesSalon.editStatus = 'update'
    },
    createService: (state: SalonState, action: PayloadAction<SalonServices | undefined>) => {
      state.servicesSalon.activeService = action.payload
      state.servicesSalon.editStatus = 'create'
      state.servicesSalon.disabled = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientsByPointId.pending, (state: Draft<SalonState>) => {
      state.clientSalon.loading = true
    })
    builder.addCase(getClientsByPointId.fulfilled, (state: Draft<SalonState>, action) => {
      state.clientSalon.clientsPoint = action.payload
      state.clientSalon.loading = false
    })
    builder.addCase(updateSalon.pending, (state: Draft<SalonState>) => {
      state.loading = true
      state.saving = true
    })
    builder.addCase(updateSalon.fulfilled, (state: Draft<SalonState>, action) => {
      state.loading = false
      state.saving = false
    })
    builder.addCase(createSalon.pending, (state: Draft<SalonState>) => {
      state.loading = true
    })
    builder.addCase(createSalon.fulfilled, (state: Draft<SalonState>, action) => {
      state.disabledEditSalon = true
      state.loading = false
    })

    builder.addCase(getStaffByPointId.pending, (state: Draft<SalonState>) => {
      state.staffSalon.loading = true
      state.saving = true
    })
    builder.addCase(getStaffByPointId.fulfilled, (state: Draft<SalonState>, action) => {
      state.staffSalon.loading = false
      state.saving = false
      state.staffSalon.staffPoint = action.payload
    })

    builder.addCase(getServicesSalon.fulfilled, (state: Draft<SalonState>, action) => {
      state.servicesSalon.loading = false
      state.saving = false
      state.servicesSalon.services = action.payload
    })
    builder.addCase(loadSystemServices.fulfilled, (state: Draft<SalonState>, action) => {
      state.servicesSalon.systemServices = action.payload.service
    })
    //Удаление сервиса салона
    builder.addCase(deleteServiceSalon.pending, (state: Draft<SalonState>) => {
      state.saving = true
      state.servicesSalon.loading = true
    })
    builder.addCase(deleteServiceSalon.fulfilled, (state: Draft<SalonState>) => {
      state.servicesSalon.activeService = undefined
      state.servicesSalon.disabled = true
      state.servicesSalon.loading = false
      state.saving = false
    })
    //Создание сервиса салона
    builder.addCase(createServicesSalon.pending, (state: Draft<SalonState>) => {
      state.saving = true
      state.servicesSalon.loading = true
    })
    builder.addCase(createServicesSalon.rejected, (state: Draft<SalonState>) => {
      state.saving = false
      state.servicesSalon.loading = false
    })
    builder.addCase(createServicesSalon.fulfilled, (state: Draft<SalonState>) => {
      state.servicesSalon.activeService = undefined
      state.servicesSalon.disabled = true
      state.servicesSalon.loading = false
      state.saving = false
    })
    //Обновеление сервиса салона
    builder.addCase(updateServiceSalon.pending, (state: Draft<SalonState>) => {
      state.saving = true
      state.servicesSalon.loading = true
    })
    builder.addCase(updateServiceSalon.fulfilled, (state: Draft<SalonState>) => {
      state.servicesSalon.disabled = true
      state.servicesSalon.loading = false
      state.saving = false
    })

    builder.addCase(getWorkersBySpecialization.fulfilled, (state: Draft<SalonState>, action) => {
      state.servicesSalon.staff = action.payload
    })

    builder.addCase(updateSalonProfile.pending, (state: Draft<SalonState>) => {
      state.loading = true
    })
    builder.addCase(updateSalonProfile.fulfilled, (state: Draft<SalonState>) => {
      state.loading = false
    })

    builder.addCase(deleteSalon.pending, (state: Draft<SalonState>) => {
      state.loading = true
    })
    builder.addCase(deleteSalon.fulfilled, (state: Draft<SalonState>) => {
      state.loading = false
    })


  }
})

export const salonsReducer = salonsSlice.reducer

export const { changeView,
  setPoint,
  createPoint,
  disabledClientChange,
  disabledSalonChange,
  setClient,
  createClient,
  setActiveSalon,
  setStaff,
  createStaff,
  disabledStaffChange,
  disabledServiceChange,
  setService,
  createService,
  onChangePanelStaff } = salonsSlice.actions

export const selectSalonClient = (state: RootState) => state.salons.clientSalon
export const selectSalonStaff = (state: RootState) => state.salons.staffSalon
export const selectSalonServices = (state: RootState) => state.salons.servicesSalon