import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadFeatures, loadCurrentTariff, loadAllTariffs, loadAllFeatures } from "./thunks";
import { Option, Service, TariffData, ActiveTariffData, FeaturesData } from "src/entities/Rates";
import { RootState } from "../index";

interface TariffState {
  allTariffs: TariffData,
  allFeatures: FeaturesData,
  tariffInfo: {
    id: number;
    name: string | null;
    features: string[];
    highlight: boolean;
    price: number;
    period: number;
    wsbSubscription: string;
  },
  currentTariff : ActiveTariffData;
  featureSlugs: string[];
  trialDate: string;
  activeDate: string;
}

const initialState: TariffState = {
  allTariffs: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  allFeatures: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  tariffInfo: {
    id: 0,
    name: '',
    features: [],
    highlight: false,
    price: 0,
    period: 0,
    wsbSubscription: '',
  },
  currentTariff : {
    activeTariff: {
      tariff: {
        id: 0,
        name: "",
        parent: null,
        options: [],
        features: [],
        highlight: false,
        slug: ""
      },
      expireDatetime: "",
      price: 0,
      trial: false,
      period: 0,
      optionId: 0,
      paymentType: null
    },
    features: []
  },
  featureSlugs: [],
  trialDate: '',
  activeDate: '',
};

const tariffSlice = createSlice({
  name: "tariff",
  initialState,
  reducers: {
    selectTariff: (state, action: PayloadAction<TariffState["tariffInfo"]>) => {
      state.tariffInfo = action.payload;
    },
    setTrialDate: (state, action: PayloadAction <string>) => {
      state.trialDate = action.payload;
    },
    setActiveDate: (state, action: PayloadAction <string>) => {
      state.activeDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFeatures.fulfilled,(state, action) => {
        state.featureSlugs = action.payload;
      }
    );
    builder.addCase(loadCurrentTariff.fulfilled,(state, action) => {
      state.currentTariff = action.payload;
      }
    );
    builder.addCase(loadAllTariffs.fulfilled,(state, action) => {
      state.allTariffs = action.payload;
      }
    );
    builder.addCase(loadAllFeatures.fulfilled,(state, action) => {
      state.allFeatures = action.payload;
      }
    );
  },
});

export const { selectTariff, setTrialDate, setActiveDate } = tariffSlice.actions;
export const featureSlugsState = (state: RootState) => {
  return state.tariff.featureSlugs;
}
export const currentTariffState = (state: RootState) => {
  return state.tariff.currentTariff.activeTariff;
}
export const currentFeaturesState = (state: RootState) => {
  return state.tariff.currentTariff.features;
}
export const allTariffState = (state: RootState) => {
  return state.tariff.allTariffs;
}
export const allFeaturesState = (state: RootState) => {
  return state.tariff.allFeatures;
}
export const tariffReducer = tariffSlice.reducer;

/* interface TariffState {
  tariffInfo: {
    name: string | null;
    selectedPeriod: number; 
    options: Option[];
    features: string[];
    highlight: boolean;
  },
  currentTariff : {
    expireDatetime: string;
    price: number;
    tariff: {
        features: string[];
        highlight: boolean;
        id: number;
        name: string;
        options: string[];
        parent: null | any;
        slug: string;
    };
    trial: boolean;
    period: number;
  }  
  featureSlugs: string[];
  trialDate: string;
  activeDate: string;
}

const initialState: TariffState = {
  tariffInfo: {
    name: '',
    selectedPeriod: 1,
    options: [],
    features: [],
    highlight: false,
  },
  currentTariff : {
    tariff: {
      features: [],
      highlight: false,
      id: 0,
      name: '',
      options: [],
      parent: '',
      slug: '',
    },
    expireDatetime: '',
    price: 0,
    period: 0,
    trial: false,
  },
  featureSlugs: [],
  trialDate: '',
  activeDate: '',
};

const tariffSlice = createSlice({
  name: "tariff",
  initialState,
  reducers: {
    selectTariff: (state, action: PayloadAction<TariffState["tariffInfo"]>) => {
      state.tariffInfo = action.payload;
    },
    setTrialDate: (state, action: PayloadAction <string>) => {
      state.trialDate = action.payload;
    },
    setActiveDate: (state, action: PayloadAction <string>) => {
      state.activeDate = action.payload;
    },
    clearRate: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadFeatures.fulfilled,(state, action) => {
        state.featureSlugs = action.payload;
      }
    );
    builder.addCase(loadCurrentTariff.fulfilled,(state, action) => {
      state.currentTariff = action.payload;
    }
  );
  },
});

export const { selectTariff, clearRate, setTrialDate, setActiveDate } = tariffSlice.actions;
export const featureSlugsState = (state: RootState) => {
  return state.tariff.featureSlugs;
}
export const currentTariffState = (state: RootState) => {
  return state.tariff.currentTariff;
}
export const tariffReducer = tariffSlice.reducer; */