import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonEvent, MasterEvent, ServiceEvent } from "../../../entities/Event";
import { agent, encodeGetParams } from "../../../api";
import { apiUrlV1 } from "../../../config/config";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import format from "date-fns/format";
import {DayWorkload, ScheduleIntervals} from "../../../entities/Workload";
import { fixDate } from "src/utils/fixDate";

interface LoadEventsParams {
  date: Date,
}

export const addServiceEvent = createAsyncThunk(
  'events/addServiceEvent',
  async (event: ServiceEvent) => {
    const res = await agent.post(`${apiUrlV1}/b2c/service-events/`, {
      body: JSON.stringify(event),
    });

    if (res.status !== 201) {
      throw new Error('Cannot add a service event');
    }
  }
)

export const addCommonEvent = createAsyncThunk(
  'events/addCommonEvent',
  async (event: CommonEvent) => {
    const res = await agent.post(`${apiUrlV1}/b2c/common-events/`, {
      body: JSON.stringify(event),
    });

    if (res.status !== 201) {
      throw new Error('Cannot add a service event');
    }
  }
)

export const loadServiceEvents = createAsyncThunk(
  'events/loadServiceEvents',
  async ({ date }: LoadEventsParams) => {
    const params = encodeGetParams({
      start_datetime__gte: fixDate(date),
      /* start_datetime__lte: fixDate(endOfDay(date)), */
    });

    const res = await agent.get(`${apiUrlV1}/b2c/service-events/` + params);

    if (res.status === 200) {
      const resBody = await res.json();
      const serviceEvents: ServiceEvent[] = resBody.results;
      return serviceEvents;
    }

    throw Error('Service events cannot be loaded');
  }
);

export const loadMasterEvents = createAsyncThunk(
  'events/loadMasterEvents',
  async ({ date }: LoadEventsParams) => {
    const params = encodeGetParams({
      start_datetime__gte: fixDate(startOfDay(date)),
      start_datetime__lte: fixDate(endOfDay(date)),
    });
    
    return await Promise.all([
      agent.get(`${apiUrlV1}/b2c/service-events/` + params),
      agent.get(`${apiUrlV1}/b2c/common-events/` + params),
    ]).then(responses => {
      return Promise.all(responses.map(r => {
        return r.json();
      }));
    }).then(resBodies => {
      return resBodies.map((b: any) => b.results as MasterEvent).flat();
    }).catch(() => {
      throw Error('could`t load events');
    });
  }
);

export const loadMasterEventById = createAsyncThunk(
  'events/loadMasterEventById',
  async (id: number) => {
    const res = await agent.get(`${apiUrlV1}/b2c/events/${id}/`);

    if (res.status === 200) {
      const body = await res.json();
      return body as MasterEvent;
    }

    throw Error('MasterEvent not found');
  }
)

export const saveMasterEvent = createAsyncThunk(
  'events/saveMasterEvent',
  async (masterEvent: MasterEvent) => {
    const eventTypeRoutePart = masterEvent.type + '-events';
    const response = await agent.put(`${apiUrlV1}/b2c/${eventTypeRoutePart}/${masterEvent.id}/`, {
      body: JSON.stringify(masterEvent),
    })
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }
)

export const cancelServiceEvent = createAsyncThunk(
  'events/cancelServiceEvent',
  async (serviceEvent: MasterEvent) => {
    const response = await agent.delete(`${apiUrlV1}/b2c/cancel-order/${serviceEvent.id}/`);
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }
)

export const clientDidntCome = createAsyncThunk(
  'events/clientDidntCome',
  async (id: number) => {
    const response = await agent.delete(`${apiUrlV1}/b2c/client-skipped-order/${id}/`);
    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }
  }
)

interface UserWorkloadParams {
  start: Date,
  end: Date,
  userId?: number,
  withoutSchedule?: boolean,
}

export const loadUserWorkload = createAsyncThunk(
  'events/loadUserWorkload',
  async ({ start, end }: UserWorkloadParams) => {
    const encodedParams = encodeGetParams({
      'start_date': format(start, 'yyyy-MM-dd'),
      'end_date': format(end, 'yyyy-MM-dd'),
    });

    const res = await agent.get(`${apiUrlV1}/user-statistics/user-workload-statistics/${encodedParams}`);

    if (res.status === 200) {
      const workload = await res.json();

      if (!workload) {
        throw Error('No workload!');
      }

      return workload as DayWorkload[];
    }

    throw Error('No workload loaded!');
  }
)

export const loadUserScheduleIntervals = createAsyncThunk(
  'events/loadUserScheduleIntervals',
  async ({ start, end, userId, withoutSchedule }: UserWorkloadParams) => {
    const encodedParams = encodeGetParams({
      'user_id': `${userId}`,
      'start_date': format(start, 'yyyy-MM-dd'),
      'end_date': format(end, 'yyyy-MM-dd'),
      'without_schedule': withoutSchedule ? `${withoutSchedule}` : false,
    });

    const res = await agent.get(`${apiUrlV1}/b2c/schedule-intervals/${encodedParams}`);

    if (!res.ok) {
      throw Error('No scheduleIntervals loaded!');
    }

    const scheduleIntervals = await res.json();

    return scheduleIntervals as ScheduleIntervals[];
  }
)