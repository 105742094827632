import { createSlice } from "@reduxjs/toolkit";
import { Consumable } from "src/entities/Consumable";
import { RootState } from "..";
import { consumablesAdd, consumablesDelete, consumablesGet, consumablesProductGet, consumablesUpdate } from "./thunks";

export const ZIRO_CONSUMABLE = {
  name: '',
  price: '',
  isInList: true
}

interface ConsumablesState {
  loading: boolean,
  adding: boolean,
  added: boolean,
  error: boolean,
  update: boolean,
  consumables: Consumable[]
  consumablesProduct: Consumable[],
  activeConsumable: Consumable
}

const initialState: ConsumablesState = {
  loading: false,
  adding: false,
  added: false,
  error: false,
  update: false,
  consumables: [],
  consumablesProduct: [],
  activeConsumable: ZIRO_CONSUMABLE
};

const consumablesSlice = createSlice({
  name: "consumables",
  initialState,
  reducers: {
    setActiveConsumable: (state, action) => {
      state.activeConsumable = action.payload
    },
    updateConsumable: (state, action) => {
      state.update = true
      state.activeConsumable = action.payload
    },
    unUpdate: (state) => {
      state.update = false
      state.activeConsumable = ZIRO_CONSUMABLE
    },
  },
  extraReducers: (builder) => {
    builder.addCase(consumablesGet.pending, state => {
      state.loading = true;
    });
    builder.addCase(consumablesGet.fulfilled, (state, action) => {
      state.loading = false;
      state.consumables = action.payload;
    });

    builder.addCase(consumablesProductGet.fulfilled, (state, action) => {
      state.loading = false;
      state.consumablesProduct = action.payload;
    });


    builder.addCase(consumablesAdd.pending, state => {
      state.adding = true
      state.added = false
      state.error = false
    });
    builder.addCase(consumablesAdd.rejected, state => {
      state.adding = false
      state.error = true
    });
    builder.addCase(consumablesAdd.fulfilled, state => {
      state.adding = false
      state.added = true
    });

    builder.addCase(consumablesUpdate.pending, state => {
      state.adding = true
      state.added = false
    });
    builder.addCase(consumablesUpdate.fulfilled,state => {
      state.update = false
      state.adding = false
      state.added = true
      state.activeConsumable = ZIRO_CONSUMABLE
    });

    builder.addCase(consumablesDelete.pending, state => {
      state.adding = true
      state.added = false
    });
    builder.addCase(consumablesDelete.fulfilled,state => {
      state.adding = false
      state.added = true
    });
  }
});

export const consumablesReducer = consumablesSlice.reducer;

export const { setActiveConsumable, updateConsumable, unUpdate } = consumablesSlice.actions;

export const selectConsumablesState = ((state: RootState) => state.consumables) 