import UserProfile, { PersonalInfo } from "../../../entities/UserProfile";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { fetchSalonProfile, fetchUserProfile, individualWeekdays, 
          testFetchUserProfile, userLogOut, deleteUserProfile } from "./profileLoad";
import { SalonInfoReq } from "src/entities/Salon";
import { IndividualWeekdays } from "src/entities/Schedule";

interface AuthState {
  token: string | null;
  profile: UserProfile | null;
  loading: boolean;
  salon: SalonInfoReq | null,
  individualWeekdays?: IndividualWeekdays
}

const localStorageToken: string | null = localStorage.getItem('token') || null;

const initialState: AuthState = {
  token: localStorageToken,
  profile: null,
  loading: !!localStorageToken,
  salon: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeUserProfile: (state, action: PayloadAction<UserProfile>) => {
      state.profile = action.payload;
      state.loading = false;
    },
    mergeUserProfile: (state, action: PayloadAction<Partial<UserProfile>>) => {
      if (state.profile) {
        state.profile = { ...state.profile, ...action.payload };
      } else {
        throw Error('Nothing to merge! Be sure to merge only if profile state is not null');
      }
      state.loading = false;
    },
    changeUserPersonalInfo: (state, action: PayloadAction<PersonalInfo>) => {
      if (state.profile) {
        state.profile.personalInfo = action.payload;
      }
    },
    changeUserToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    makeUserLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.pending, (state: Draft<AuthState>, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state: Draft<AuthState>, action) => {
      state.profile = action.payload;
      state.loading = false;
    });

    builder.addCase(testFetchUserProfile.pending, (state: Draft<AuthState>) => {
      state.loading = true;
    });
    builder.addCase(testFetchUserProfile.fulfilled, (state: Draft<AuthState>, action) => {
      state.profile = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSalonProfile.pending, (state: Draft<AuthState>) => {
      state.loading = true;
    });
    builder.addCase(fetchSalonProfile.fulfilled, (state: Draft<AuthState>, action) => {
      state.salon = action.payload
      state.loading = false
    })

    builder.addCase(userLogOut.fulfilled, (state: Draft<AuthState>, action) => {
      state.profile = null;
      state.loading = false;
      state.token = null;
    });
    builder.addCase(individualWeekdays.fulfilled, (state: Draft<AuthState>, action) => {
      state.individualWeekdays = action.payload
    });

    builder.addCase(deleteUserProfile.pending, (state: Draft<AuthState>, action) => {
      state.loading = true;
    });
    builder.addCase(deleteUserProfile.fulfilled, (state: Draft<AuthState>, action) => {
      state.profile = null;
      state.loading = false;
    });
  }
});

export const { changeUserProfile, changeUserToken, changeUserPersonalInfo, mergeUserProfile, makeUserLoading } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;
export const selectUserProfile = (state: RootState) => state.auth.profile;
export const selectSalonProfile = (state: RootState) => state.auth.salon?.points;

export default authSlice.reducer;