import {useMediaQuery, useTheme} from "@material-ui/core";
import React, {useContext} from "react";

export const useIsMobileContextValue = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

type IsMobileContextType = ReturnType<typeof useIsMobileContextValue>;

export const IsMobileContext = React.createContext<IsMobileContextType>({} as IsMobileContextType);

export const useIsMobile = () => {
  return useContext(IsMobileContext);
}
