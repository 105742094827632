import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { Messenger } from "src/entities/Messengers";
import { loadMessengers, updateMessengers } from "./thunk";

interface MessengersStore {
  messengersState: {
    loading: boolean,
    messengers: Messenger[],
  },
}
const initialState: MessengersStore = {
  messengersState: {
    loading: false,
    messengers: [],
  },

};

const messengerSlice = createSlice({
  name: 'messengers',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadMessengers.pending, (state) => {
      state.messengersState.loading = true;
    });
    builder.addCase(loadMessengers.fulfilled, (state, thunkApi) => {
      state.messengersState.loading = false;
      state.messengersState.messengers = thunkApi.payload;
    });

    builder.addCase(updateMessengers.pending, (state) => {
      state.messengersState.loading = true;
    });
    builder.addCase(updateMessengers.fulfilled, (state, thunkApi) => {
      state.messengersState.loading = false;
      state.messengersState.messengers = thunkApi.payload;
    });
  },
});

export const { reducer: messengersReducer } = messengerSlice;

export const selectMessengersState = (state: RootState) => {
  return state.messengers.messengersState.messengers;
}
