import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../../api";
import { apiUrlV1 } from "../../../config/config";
import { fetchUserProfile, userLogOut } from "../auth/profileLoad";

interface UpdateSalon {
  avatar_id: number | null,
  first_name: string,
  last_name: string,
  email: string,
}

export const updateSalonProfile = createAsyncThunk(
  'salon/update/profile',
  async (settings: UpdateSalon, thunkApi): Promise<UpdateSalon> => {
    const res = await agent.put(`${apiUrlV1}/salon/settings/`, {
      body: JSON.stringify(settings),
    });

    if (res.status === 200) {
      thunkApi.dispatch(fetchUserProfile());
      return await res.json();
    }

    throw Error(`Settings cannot be updated: unknown reason`);
  }
);


export const deleteSalon = createAsyncThunk(
  'salon/delete',
  async (_, thunkApi) => {
    const res = await agent.delete(`${apiUrlV1}/salon/settings/delete`);

    if (res.status === 200) {
      localStorage.removeItem('salonPoint');
      thunkApi.dispatch(userLogOut());
      return await res.json();
    }

    throw Error('Salon not removed');

  }
)