import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../../api";
import { apiUrlV1 } from "../../../config/config";
import { Messenger, Messengers } from "src/entities/Messengers";

export const loadMessengers = createAsyncThunk(
  'events/loadMessengers',
  async () => {
    const res = await agent.get(`${apiUrlV1}/profiles/messengers/?is_enabled=false`);

    if (!res.ok) {
      throw Error('No messengers loaded!');
    }

    const messengers = await res.json();

    return messengers.messengers as Messenger[];
  }
)

export const updateMessengers = createAsyncThunk(
  'events/updateMessengers',
  async (messengers: Messengers) => {
    const res = await agent.put(`${apiUrlV1}/profiles/messengers/update/`, {
      body: JSON.stringify(messengers),
    })
    if (!res.ok) {
      throw new Error(`Request failed with status ${res.status}`);
    }

    const body = await res.json();
    
    return body as Messenger[];
  }
)