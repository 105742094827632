import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dates, Schedule, ScheduleFixed, ScheduleIndividual, ScheduleShifted } from "../../../entities/Schedule";
import { apiUrlV1 } from "../../../config/config";
import { agent } from "../../../api";
import merge from "lodash/merge";
import { defaultFixedSchedule, defaultIndividualSchedule, defaultShiftedSchedule } from "./scheduleStore";
import { format } from "date-fns";

export const loadSchedules = createAsyncThunk(
  'schedule/loadSchedules',
  async () => {
    return Promise.all([
      agent.get(`${apiUrlV1}/schedule-fixed/`),
      agent.get(`${apiUrlV1}/schedule-shifted/`),
      agent.get(`${apiUrlV1}/schedule-individual/`),
    ]).then(arr => {
      return Promise.all(arr.map(i => i.status === 200 ? i.json() : Promise.resolve({})));
    }).then(([fixedBody, shiftedBody, individualBody]) => {
      return [
        merge(defaultFixedSchedule(), fixedBody) as ScheduleFixed,
        merge(defaultShiftedSchedule(), shiftedBody) as ScheduleShifted,
        merge(defaultIndividualSchedule(), individualBody) as ScheduleIndividual,
      ];
    }).catch(() => {
      throw Error('Error loadSchedules!');
    })
  }
)
export const loadDatesIndividual = createAsyncThunk(
  'schedule/dates',
  async () => {
    const res = await agent.get(`${apiUrlV1}/dates/?date__gte=${format(new Date(), 'yyyy-MM-dd')}`)
    if (res.status === 200) {
      const dates = await res.json()
      return dates as Dates[]
    }

    throw Error('Schedule not saved due some error!');
  }
)

export const saveUserSchedule = createAsyncThunk(
  'schedule/save',
  async (schedule: Schedule) => {
    const url = `${apiUrlV1}/schedule-${schedule.type}/${schedule.id || ''}/`;
    const res = await agent.post(url, {
      method: schedule.id ? 'PUT' : 'POST',
      body: JSON.stringify({ ...schedule, isActive: true })
    });

    if (res.status === 201 || res.status === 200) {
      const schedule: Schedule = await res.json();
      return schedule;
    }

    throw Error('Schedule not saved due some error!');
  }
);

export const updateDateIndividual = createAsyncThunk(
  'schedule/update_dates',
  async (schedule: Dates, thunkApi) => {
    const res = await agent.put(`${apiUrlV1}/dates/${schedule.id}`, {
      body: JSON.stringify({ ...schedule })
    })
    thunkApi.dispatch(loadDatesIndividual());
  }
)

export const deleteDateIndividual = createAsyncThunk(
  'schedule/delete_dates',
  async (id: number[], thunkApi) => {
    const res = await agent.delete(`${apiUrlV1}/dates/bulk_delete`, {
      body: JSON.stringify({id_list: [...id]})
    })

    if (res.status === 200 || res.status === 204)
      thunkApi.dispatch(loadDatesIndividual());
  }
)

export const bulkCreateIndividual = createAsyncThunk(
  'schedule/update_dates',
  async (schedule: Omit<Dates, 'id'>[], thunkApi) => {
    const res = await agent.post(`${apiUrlV1}/dates/bulk_create/`, {
      body: JSON.stringify({ dates: [...schedule] })
    })

    if (res.status === 200 || res.status === 204 || res.status === 201) {
      thunkApi.dispatch(loadDatesIndividual());
    }
    else {
      throw Error('Schedule not saved due some error!');
    }
  }
)

export const loadInvidualSchedule = createAsyncThunk(
  'schedule/loadInvidualSchedule',
  async ({userId, fromToday} : {userId: number, fromToday?: boolean}) => {
    const res = await agent.get(`${apiUrlV1}/schedule-individual/?user_id=${userId}&from_today=${fromToday}`)
    if (!res.ok) {
      throw Error('No invidual schedule loaded!');
    }

    const invidualSchedule = await res.json();

    return invidualSchedule as ScheduleIndividual;
  }
)