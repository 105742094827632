import {createSlice} from "@reduxjs/toolkit";
import {Client, clientFullName} from "../../../entities/Client";
import {addClient, loadClientById, loadClients, removeClient, restoreClient, updateClient} from "./thunks";
import {RootState} from "../index";
import {sortByString} from "../../../utils/sortByString";

const name = 'clients';

interface ClientsState {
  status: 'none' | 'adding' | 'updating' | 'added' | 'updated' | 'loading',
  errorStatus: 'none' | 'phoneExists' | 'wrongData',
  clients: Record<number, Client>,
}

const initialState: ClientsState = {
  status: 'none',
  errorStatus: 'none',
  clients: {},
};

const clientsSlice = createSlice({
  name,
  initialState,
  reducers: {
    initAdding: state => {
      state.status = 'adding';
      state.errorStatus = 'none';
    },
    initUpdating: state => {
      state.status = 'updating';
      state.errorStatus = 'none';
    },
    resetStatus: state => {
      state.status = 'none';
      state.errorStatus = 'none';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadClients.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(loadClients.fulfilled, (state, action) => {
      state.clients = {};
      action.payload.reduce((acc, i) => {
        acc[i.id] = i;
        return acc;
      }, state.clients);
      state.status = 'none';
    });

    builder.addCase(loadClientById.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(loadClientById.fulfilled, (state, action) => {
      state.clients[action.payload.id] = action.payload;
      state.status = 'none';
    });

    builder.addCase(addClient.pending, (state) => {
      state.errorStatus = 'none';
    });
    builder.addCase(addClient.fulfilled, (state, action) => {
      const client: Client = action.payload;
      state.clients[client.id] = client;
      state.status = 'added';
      state.errorStatus = 'none';
    });
    builder.addCase(addClient.rejected, (state, action) => {
      state.errorStatus = 'phoneExists';
    });

    builder.addCase(updateClient.fulfilled, (state, action) => {
      const client: Client = action.payload;
      state.clients[client.id] = client;
      state.status = 'updated';
      state.errorStatus = 'none';
    });
    builder.addCase(updateClient.rejected, (state, action) => {
      state.errorStatus = 'wrongData';
    });

    builder.addCase(removeClient.pending, (state, action) => {
      state.clients[action.meta.arg.id].isRemoved = true;
    });
    builder.addCase(restoreClient.pending, (state, action) => {
      state.clients[action.meta.arg.id].isRemoved = false;
    });
    builder.addCase(restoreClient.fulfilled, (state, action) => {
      delete state.clients[action.payload.prev.id];
      state.clients[action.payload.next.id] = action.payload.next;
    });
  }
});

export const clientsReducer = clientsSlice.reducer;

export const {initAdding, initUpdating, resetStatus} = clientsSlice.actions;

export const selectStatus = (state: RootState) => {
  return state.clients.status;
};

export const selectErrorStatus = (state: RootState) => {
  return state.clients.errorStatus;
};

export const selectClients = (state: RootState) => {
  return Object
    .values(state.clients.clients)
    .sort((c1, c2) => sortByString(clientFullName(c1), clientFullName(c2)));
};

export const selectClientById = (clientId: number) => (state: RootState) => {
  return state.clients.clients[clientId];
}