import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  Dates,
  Schedule,
  ScheduleDay,
  ScheduleFixed,
  ScheduleIndividual,
  ScheduleShifted,
  WorkShift
} from "../../../entities/Schedule";
import { RootState } from "../index";
import {
  bulkCreateIndividual,
  deleteDateIndividual,
  loadDatesIndividual,
  loadInvidualSchedule,
  loadSchedules,
  saveUserSchedule,
} from "./thunks";
import format from "date-fns/format";

export const defaultScheduleDay = (weekday: number): ScheduleDay => {
  return { weekday, startTime: '09:00', endTime: '21:00' };
};

export const defaultFixedSchedule = (): ScheduleFixed => ({
  type: 'fixed',
  days: []
});

export const defaultShiftedSchedule = (): ScheduleShifted => ({
  type: 'shifted',
  startDate: format(new Date(), 'yyyy-MM-dd'),
  workShift: {
    workingDaysCount: 2,
    holidaysCount: 2,
    startTime: '09:00',
    endTime: '21:00',
  }
});

export const defaultIndividualSchedule = (): ScheduleIndividual => ({
  type: 'individual'
});

export interface ScheduleState {
  currentSchedule: Schedule | undefined,
  removedDays: Record<number, ScheduleDay>,
  fixedSchedule: ScheduleFixed | undefined,
  shiftedSchedule: ScheduleShifted | undefined,
  individualSchedule: ScheduleIndividual | undefined,
  userSchedule: Schedule | undefined,
  dates: Dates[],
  loading: boolean,
  saving: boolean,
  saved: boolean,
  error: boolean
}

const initialState: ScheduleState = {
  currentSchedule: undefined,
  removedDays: {},
  fixedSchedule: undefined,
  shiftedSchedule: undefined,
  individualSchedule: undefined,
  userSchedule: undefined,
  dates: [],
  loading: true,
  saving: false,
  saved: false,
  error: false
};

const scheduleRemember = (state: Draft<ScheduleState>) => {
  if (state.currentSchedule?.type === 'fixed') {
    state.fixedSchedule = state.currentSchedule as ScheduleFixed;
  } else if (state.currentSchedule?.type === 'shifted') {
    state.shiftedSchedule = state.currentSchedule as ScheduleShifted;
  }
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    clearScheduleState: (state) => {
      return { ...initialState };
    },
    currentScheduleSet: (state, action: PayloadAction<Schedule | undefined>) => {
      // remember previous schedule, change and remember current
      scheduleRemember(state);
      state.currentSchedule = action.payload || defaultFixedSchedule();
      state.saved = false;
      scheduleRemember(state);
    },
    removeDay: (state, action: PayloadAction<number>) => {
      const weekday: number = action.payload;
      const schedule = state.currentSchedule;
      if (schedule?.type === 'fixed') {
        const days = (state.currentSchedule as ScheduleFixed).days;
        const dayIdx = days.findIndex(i => i.weekday === weekday);
        const day = dayIdx >= 0 && days[dayIdx];
        if (day) {
          days.splice(dayIdx, 1);
          state.removedDays[day.weekday] = day;
        }
      }
    },
    restoreDay: (state, action: PayloadAction<number>) => {
      const weekday: number = action.payload;
      const schedule = state.currentSchedule;
      if (schedule?.type === 'fixed') {
        const removedDays = state.removedDays;
        const day = removedDays[weekday] || defaultScheduleDay(weekday);
        (schedule as ScheduleFixed).days.push(day);
      }
    },
    scheduleDayUpdate: (state, action: PayloadAction<ScheduleDay>) => {
      if (state.currentSchedule?.type === 'fixed') {
        const schedule: ScheduleFixed = state.currentSchedule;
        const dayIdx = schedule.days
          .findIndex(i => i.weekday === action.payload.weekday);
        schedule.days[dayIdx] = action.payload;
      }
    },
    scheduleDayBreakRemove: (state, action: PayloadAction<ScheduleDay>) => {
      if (state.currentSchedule?.type === 'fixed') {
        const day = action.payload;
        const schedule: ScheduleFixed = state.currentSchedule;
        const dayIdx = schedule.days.findIndex(i => i.weekday === day.weekday);

        if (day) {
          schedule.days[dayIdx] = {
            ...day,
            breakStartTime: undefined,
            breakEndTime: undefined
          };
        }
      }
    },
    scheduleDayBreakRestore: (state, action: PayloadAction<ScheduleDay>) => {
      if (state.currentSchedule?.type === 'fixed') {
        const schedule: ScheduleFixed = state.currentSchedule;
        const day = { ...action.payload };
        const [hours, minutes] = day.startTime ? day.startTime.split(':') : [0, 0];
        day.breakStartTime = '13:00';
        day.breakEndTime = '14:00';

        schedule.days = schedule.days.map(i => {
          if (i.weekday === day.weekday) {
            return day;
          }
          return i;
        });
      }
    },
    scheduleDayAcceptOnAll: (state, action: PayloadAction<ScheduleDay>) => {
      if (state.currentSchedule?.type === 'fixed') {
        const schedule: ScheduleFixed = state.currentSchedule;
        const day = { ...action.payload };

        schedule.days = schedule.days.map(i => {
          const weekday = i.weekday;
          return { ...day, weekday };
        });

        state.removedDays = Array(7)
          .fill(day)
          .map((d, idx) => ({ ...d, weekday: idx + 1 }))
          .reduce(
            (acc, d) => ({ ...acc, [d.weekday]: d }),
            {}
          );
      }
    },
    workShiftUpdate: (state, action: PayloadAction<WorkShift>) => {
      if (state.currentSchedule?.type === 'shifted') {
        const workShift: WorkShift = action.payload;
        state.currentSchedule.workShift = { ...workShift };
      }
    },
    startDateUpdate: (state, action: PayloadAction<string>) => {
      if (state.currentSchedule) {
        state.currentSchedule.startDate = action.payload;
      }
    },
  },
  extraReducers: (builder => {
    builder.addCase(loadSchedules.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadSchedules.fulfilled, (state, action) => {
      const [scheduleFixed, scheduleShifted, scheduleIndividual] = action.payload;
      const activeSchedule = action.payload.filter((i: Schedule) => i?.isActive)[0];
      state.fixedSchedule = scheduleFixed as ScheduleFixed;
      state.shiftedSchedule = scheduleShifted as ScheduleShifted;
      state.individualSchedule = scheduleIndividual as ScheduleIndividual;
      state.currentSchedule = activeSchedule || scheduleFixed || defaultFixedSchedule();
      state.userSchedule = activeSchedule || defaultFixedSchedule();
      state.loading = false;
    });

    builder.addCase(loadInvidualSchedule.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loadInvidualSchedule.fulfilled, (state, action) => {
      state.individualSchedule = action.payload;
      state.loading = false;
    });

    builder.addCase(saveUserSchedule.pending, (state) => {
      state.saved = false;
      state.saving = true;
    });
    builder.addCase(saveUserSchedule.fulfilled, (state, action) => {
      const schedule: Schedule = action.payload;

      if (schedule.type === 'fixed') {
        state.fixedSchedule = schedule;
      } else if (schedule.type === 'shifted') {
        state.shiftedSchedule = schedule;
      } else if (schedule.type === 'individual') {
        state.individualSchedule = schedule
      }

      if (schedule.type === state.currentSchedule?.type) {
        state.currentSchedule = schedule;
      }

      state.userSchedule = schedule; 
      state.saved = true;
      state.saving = false;
    });
    builder.addCase(saveUserSchedule.rejected, (state) => {
      state.saving = false;
      state.saved = false;
    });
    builder.addCase(loadDatesIndividual.fulfilled, (state, action) => {
      state.dates = action.payload
    });

    builder.addCase(deleteDateIndividual.pending, (state) => {
      state.saved = false;
      state.saving = true;
    });
    builder.addCase(deleteDateIndividual.fulfilled, (state) => {
      state.saved = true;
      state.saving = false;
    });

    builder.addCase(bulkCreateIndividual.pending, (state) => {
      state.saved = false;
      state.saving = true;
      state.error = false;
    });
    builder.addCase(bulkCreateIndividual.rejected, (state) => {
      state.saving = false;
      state.error = true;
    });
    builder.addCase(bulkCreateIndividual.fulfilled, (state) => {
      state.saved = true;
      state.saving = false;
    });
  })
});

export const {
  clearScheduleState, currentScheduleSet, removeDay, restoreDay, scheduleDayUpdate,
  scheduleDayBreakRemove, scheduleDayBreakRestore, scheduleDayAcceptOnAll,
  workShiftUpdate, startDateUpdate
} = scheduleSlice.actions;

export const selectScheduleState = (state: RootState) => {
  return state.schedule;
}

export const selectCurrentSchedule = (state: RootState) => {
  return state.schedule.currentSchedule;
}

export const selectUserSchedule = (state: RootState) => {
  return state.schedule.userSchedule;
}

export const selectFixedSchedule = (state: RootState) => {
  if (state.schedule.currentSchedule?.type === 'fixed') {
    return state.schedule.currentSchedule as ScheduleFixed;
  }
  return state.schedule.fixedSchedule || defaultFixedSchedule();
}

export const selectShiftedSchedule = (state: RootState) => {
  if (state.schedule.currentSchedule?.type === 'shifted') {
    return state.schedule.currentSchedule as ScheduleShifted;
  }
  return state.schedule.shiftedSchedule || defaultShiftedSchedule();
}

export const selecIndividualSchedule = (state: RootState) => {
  if (state.schedule.currentSchedule?.type === 'individual') {
    return state.schedule.currentSchedule as ScheduleIndividual;
  }
  return state.schedule.individualSchedule || defaultIndividualSchedule();
}

export const selectDays = (state: RootState) => {
  const schedule = selectFixedSchedule(state);
  return schedule.days;
}

export const selectFirstOrInitDay = (state: RootState) => {
  return defaultScheduleDay(1);
}

export const selectWorkShift = (state: RootState) => {
  const schedule = selectShiftedSchedule(state);
  return schedule.workShift;
};

export default scheduleSlice.reducer;
