import {createAsyncThunk} from "@reduxjs/toolkit";
import {agent} from "../../../api";
import {apiUrlV1} from "../../../config/config";
import format from "date-fns/format";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import startOfDay from "date-fns/startOfDay";
import {MasterStatistics, StatisticsWidgets} from "../../../entities/StatisticsOverview";
import setYear from "date-fns/setYear";
import addDays from "date-fns/addDays";

const overviewWidgetParams = (start: Date, end: Date) => {
  return {
    "name": "overview",
    "from_date": format(start, 'yyyy-MM-dd'),
    "to_date": format(end, 'yyyy-MM-dd'),
    "from_date_average": format(start, 'yyyy-MM-dd'),
    "to_date_average": format(end, 'yyyy-MM-dd'),
  };
};

const bySpecializationWidgetParams = (start: Date, end: Date) => {
  return {
    "name": "by_specialization",
    "from_date": format(start, 'yyyy-MM-dd'),
    "to_date": format(end, 'yyyy-MM-dd'),
  };
};

const takeWidgets = (resBody: any) => {
  const statistics: any[] = resBody.statistics;
  return statistics.reduce((acc, widget) => {
    acc[widget.name] = widget.results;
    return acc;
  }, {}) as StatisticsWidgets;
};

export const loadStatistics = createAsyncThunk(
  'statistics/loadStatistics',
  async () => {
    const now = new Date();

    const yearStart = startOfYear(now);
    const yearEnd = endOfYear(now);

    const monthStart = startOfMonth(now);
    const monthEnd = addDays(endOfMonth(now), 1);

    const dayStart = startOfDay(now);
    const dayEnd = startOfDay(addDays(now, 1));

    const allTimeStart = setYear(now, 2017);
    const allTimeEnd = dayEnd;

    const intervals = [
      [yearStart, yearEnd],
      [monthStart, monthEnd],
      [dayStart, dayEnd],
      [allTimeStart, allTimeEnd],
    ];

    return Promise.all(
      intervals.map(([start, end]) => {
        return agent.post(`${apiUrlV1}/profiles/me/statistics/`, {
          body: JSON.stringify({
            "widgets": [
              overviewWidgetParams(start, end),
              bySpecializationWidgetParams(start, end),
            ]
          })
        });
      })
    ).then((responses) => {
      return Promise.all(responses.map(r => r.json()));
    }).then(bodies => {
      const [year, month, day, allTime] = bodies.map(takeWidgets);
      const masterStatistics: MasterStatistics = {
        year, month, day, allTime
      };
      return masterStatistics;
    }).catch(() => {
      throw Error('Statistics not loaded!');
    });
  }
);

export const loadDayStatistics = createAsyncThunk(
  'statistics/loadDayStatistics',
  async () => {
    const now = new Date();

    const dayStart = startOfDay(now);
    const dayEnd = startOfDay(addDays(now, 1));


    const intervals = [
      [dayStart, dayEnd],
    ];

    return Promise.all(
      intervals.map(([start, end]) => {
        return agent.post(`${apiUrlV1}/profiles/me/statistics/`, {
          body: JSON.stringify({
            "widgets": [
              overviewWidgetParams(start, end),
              bySpecializationWidgetParams(start, end),
            ]
          })
        });
      })
    ).then((responses) => {
      return Promise.all(responses.map(r => r.json()));
    }).then(bodies => {
      const [day] = bodies.map(takeWidgets);
      return {day} as MasterStatistics;
    }).catch(() => {
      throw Error('Day statistics not loaded!');
    });
  }
);
