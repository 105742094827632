import {createSlice} from "@reduxjs/toolkit";
import {loadDayStatistics, loadStatistics} from "./thunks";
import {MasterStatistics} from "../../../entities/StatisticsOverview";
import {RootState} from "../index";

export interface StatisticsState {
  loading: boolean,
  statistics?: MasterStatistics,
}

const initialState: StatisticsState = {
  loading: false,
  statistics: undefined
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder.addCase(loadStatistics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadStatistics.fulfilled, (state, action) => {
      state.loading = false;
      state.statistics = action.payload;
    });

    builder.addCase(loadDayStatistics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadDayStatistics.fulfilled, (state, action) => {
      state.loading = false;
      state.statistics = action.payload;
    });
  }
});

export const statisticsReducer = statisticsSlice.reducer;

export const selectStatisticsState = (state: RootState) => {
  return state.statistics;
}
