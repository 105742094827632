import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActivePlace, District, workingConditionAtHomeToPlaceType,
  WorkingConditionsAtFieldWork,
  Workplaces
} from "../../../entities/Workplaces";
import { RootState } from "../index";
import { PlaceType } from "../../../entities/PlaceType";
import {
  workplaceAdd, workplaceRemove, workplaceUpdate, loadDisrictsByCity,
  loadUserDistricts, workplacePlacesRemove, workplacePlacesSave, saveUserDistricts
}
  from "./thunks";
import merge from 'lodash/merge';

const defaultWorkplaces: Workplaces = {
  id: -1,
  places: [],
  workingConditionsAtHome: [],
  workingConditionsAtFieldWork: undefined,
};

export interface WorkplacesState {
  workplaces: Workplaces,
  status: {
    adding: boolean,
  },
  placesAtHomeMap: Record<number, PlaceType>,
  saved: boolean,
  error: boolean,
  districts: District[],
  userDistricts: District[] | undefined,
}

const initialState: WorkplacesState = {
  workplaces: defaultWorkplaces,
  status: {
    adding: false,
  },
  placesAtHomeMap: {},
  saved: false,
  error: false,
  districts: [],
  userDistricts: [],
};

const workplacesSlice = createSlice({
  name: 'workplaces',
  initialState,
  reducers: {
    initWorkplaces: (state, action: PayloadAction<Workplaces | undefined>) => {
      state.workplaces = merge(action.payload, { id: 1 }, defaultWorkplaces);

      if (action.payload) {
        const workplaces: Workplaces = action.payload;
        state.placesAtHomeMap = workplaces.workingConditionsAtHome?.reduce((acc, item) => {
          acc[item.id] = workingConditionAtHomeToPlaceType(item);
          return acc;
        }, {} as Record<number, PlaceType>) || {};
      }
    },
    updateWorkplaces: (state, action: PayloadAction<Workplaces>) => {
      state.workplaces = action.payload;
    },
    mergeWorkplaces: (state, action: PayloadAction<Partial<Workplaces>>) => {
      state.workplaces = { ...state.workplaces, ...action.payload };
    },
    // when only one address
    // activateHomeWorkplace: (state, action: PayloadAction<PlaceType>) => {
    //   const place = action.payload;
    //
    //   if (place.id !== undefined) {
    //     state.workplaces.workingConditionsAtHome.forEach((i, idx) => {
    //       if (idx === place.id) {
    //         i.isActive = true;
    //       } else if (idx !== place.id && i.isActive) {
    //         i.isActive = false;
    //       }
    //     });
    //   }
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(workplaceAdd.pending, (state, action) => {
      state.status.adding = true;
      state.saved = false;
      state.error = false;
    });
    builder.addCase(workplaceAdd.fulfilled, (state, action) => {
      const place = action.payload;
      state.status.adding = false;
      if (place?.id) {
        state.placesAtHomeMap[place.id] = place;
      }
      state.saved = true;
    });
    builder.addCase(workplaceAdd.rejected, (state) => {
      state.saved = false;
      state.error = true;
    });

    builder.addCase(workplaceRemove.pending, (state, action) => {
      const place = action.meta.arg;
      if (place?.id) {
        delete state.placesAtHomeMap[place.id];
      }
    });

    builder.addCase(workplaceUpdate.pending, (state) => {
      state.status.adding = true;
      state.saved = false;

    });

    builder.addCase(workplaceUpdate.fulfilled, (state, action) => {
      const { place } = action.meta.arg;
      if (place.id !== undefined) {
        state.placesAtHomeMap[place.id] = place;
      }
      state.status.adding = false;
      state.saved = true;
      state.error = false;
    });

    builder.addCase(workplaceUpdate.rejected, (state) => {
      state.saved = false;
      state.error = true;
    });

    builder.addCase(loadDisrictsByCity.pending, (state) => {
      state.status.adding = true;
    });

    builder.addCase(loadDisrictsByCity.fulfilled, (state, action) => {
      const district = action.payload?.districtsData;
      if (district !== undefined) {
        state.districts = district;
      }
      state.status.adding = false;
    });

    builder.addCase(loadUserDistricts.pending, (state) => {
      state.status.adding = true;
    });

    builder.addCase(loadUserDistricts.fulfilled, (state, action) => {
      const districts = action.payload?.districtsData;
      if (districts !== undefined) {
        state.userDistricts = districts;
      }
      state.status.adding = false;
    });

    builder.addCase(workplacePlacesSave.pending, (state) => {
      state.status.adding = true;
      state.saved = false;
      state.userDistricts = [];
    });

    builder.addCase(workplacePlacesSave.fulfilled, (state, action) => {
      const newPlaces = action.payload;
      const places = state.workplaces.places;
      if (newPlaces) {
        state.workplaces.places = [...places, ...newPlaces];
      }
      state.status.adding = false;
      state.saved = true;
    });

    builder.addCase(workplacePlacesRemove.pending, (state) => {
      state.status.adding = true;
      state.saved = false;
    });

    builder.addCase(workplacePlacesRemove.fulfilled, (state, action) => {
      const newPlaces = action.payload;
      if (newPlaces) {
        const filteredPlaces = state.workplaces.places.filter(item => newPlaces.includes(item));
        state.workplaces.places = filteredPlaces;
      }
      state.status.adding = false;
      state.saved = true;
    });

    builder.addCase(saveUserDistricts.pending, (state) => {
      state.status.adding = true;
    });

    builder.addCase(saveUserDistricts.fulfilled, (state, action) => {
      const districts = action.payload?.districtsData;
      if (districts !== undefined) {
        state.userDistricts = districts;
      } else {
        state.userDistricts = [];
      }
      state.status.adding = false;
    });
  }
});

export const workplacesReducer = workplacesSlice.reducer;

export const {
  initWorkplaces,
  updateWorkplaces,
  mergeWorkplaces,
} = workplacesSlice.actions;

export function selectWorkplaces(state: RootState): Workplaces {
  return state.workplaces.workplaces;
}

export function selectWorkplacesState(state: RootState) {
  return state.workplaces;
}

export function selectAtHomeAsPlaces(state: RootState): PlaceType[] {
  return Object.values(state.workplaces.placesAtHomeMap);
}

export function selectWorkingConditionsAtFieldWork(state: RootState): WorkingConditionsAtFieldWork | undefined {
  return state.workplaces.workplaces.workingConditionsAtFieldWork;
}

const memoizedNewSet = (activePlaces: ActivePlace[]) => {
  return new Set(activePlaces);
}

export function selectActivePlaces(state: RootState): Set<ActivePlace> {
  return memoizedNewSet(state.workplaces.workplaces.places);
}

export function selectStatus(state: RootState) {
  return state.workplaces.status;
}

export function selectDistrictsState(state: RootState) {
  return state.workplaces.districts;
}

export function selectUserDistrictsState(state: RootState) {
  return state.workplaces.userDistricts;
}