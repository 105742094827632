import {createSlice} from "@reduxjs/toolkit";
import {loadSubscription} from "./thunks";
import {RootState} from "../index";
import {Subscription} from "../../../entities/Subscription";

export interface SubscriptionState {
  loading: boolean,
  subscription?: Subscription,
}

const initialState: SubscriptionState = {
  loading: false,
  subscription: undefined
};

export const subscriptionSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSubscription.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadSubscription.fulfilled, (state, action) => {
      state.loading = false;
      state.subscription = action.payload;
    });

    // builder.addCase(loadSubscription.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(loadSubscription.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.subscription = action.payload;
    // });
  }
});

export const subscriptionReducer = subscriptionSlice.reducer;

export const selectSubscriptionState = (state: RootState) => {
  return state.subscription;
}
