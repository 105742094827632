import { ImageFile } from "./ImageFile";

export interface Client {
  id: number | -1,
  phone: string,
  firstName: string,
  lastName: string,
  address?: string,
  location?: string,
  avatar?: ImageFile,
  isBlocked: boolean,
  isRemoved?: boolean,
  salonPoint?: number,
  eventsCount?: number,
}

export const clientFullName = (client: Client) => {
  return `${client.firstName} ${client.lastName}`;
}
