import {createAsyncThunk} from "@reduxjs/toolkit";
import {apiUrlV1} from "../../../config/config";
import UserProfile, {PersonalInfo, profileCityToPlaceType} from "../../../entities/UserProfile";
import {agent} from "../../../api";
import {getCityId} from "../../../entities/PlaceType";
import {fetchUserProfile} from "../auth/profileLoad";
import {mergeUserProfile} from "../auth/authStore";

export const loadUserProfile = createAsyncThunk(
  'profile/load',
  async () => {
    const res = await agent.get(`${apiUrlV1}/profiles/me/`);

    if (res.status === 200) {
      const body = await res.json();
      return body as UserProfile;
    }

    throw Error("Couldn't load user profile");
  },
)

export const updateUserPersonalInfo = createAsyncThunk(
  'profile/update',
  async (personalInfo: Partial<PersonalInfo>, thunkApi) => {
    const regionTitle = 'region';
    const cityId = personalInfo.cityId || personalInfo.city?.id || (
      personalInfo.city && await getCityId(profileCityToPlaceType(personalInfo.city, {regionTitle}))
    );
    const personalInfoPart: Partial<PersonalInfo> = {...personalInfo};

    delete personalInfoPart.phone;
    delete personalInfoPart.city;
    delete personalInfoPart.avatar;
    delete personalInfoPart.cover;

    const res = await agent.put(`${apiUrlV1}/profiles/me/`, {
      body: JSON.stringify({
        ...personalInfoPart,
        cityId,
        avatarId: personalInfo.avatar?.id || null,
        coverId: personalInfo.cover?.id || null,
      }),
    });

    if (res.status === 200) {
      thunkApi.dispatch(fetchUserProfile());
    }
  }
);

export const patchUserPersonalInfo = createAsyncThunk(
  'profile/patch',
  async (personalInfo: Partial<PersonalInfo>, thunkApi) => {
    const personalInfoPart: Partial<PersonalInfo> = {...personalInfo};
    const res = await agent.patch(`${apiUrlV1}/profiles/me/`, {
      body: JSON.stringify({
        ...personalInfoPart,
      }),
    });

    if (res.status === 200) {
      thunkApi.dispatch(fetchUserProfile());
    }
  }
);

export const sendSms = createAsyncThunk(
  'profile/sendSms',
  async (phoneNew: string) => {
    const res = await agent.post(`${apiUrlV1}/otp/send-sms/`, {
      body: JSON.stringify({
        phone: `+${phoneNew}`,
        type: 'ph_ch',
      }),
    });

    if (res.status === 201) {
      return;
    }

    throw Error(`Couldn't send an otp :(`);
  }
);

type PhoneChangeActionPayload = {
  phone: string,
  smsCode: string
};

export const phoneChange = createAsyncThunk(
  'profile/phoneChange',
  async (data: PhoneChangeActionPayload, thunkApi) => {
    const res = await agent.post(`${apiUrlV1}/profiles/change-phone/`, {
      body: JSON.stringify({
        phone: `+${data.phone}`,
        otpCode: data.smsCode,
      })
    });

    if (res.status === 200) {
      thunkApi.dispatch(mergeUserProfile({phone: data.phone}));
      return;
    }

    throw Error('otp wrong');
  }
);
