import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../../config/config";
import { RootState } from "../index";
import { placeToHomeWorkplace, PlaceType } from "../../../entities/PlaceType";
import { agent } from "../../../api";
import {
  ActivePlace,
  workingConditionAtHomeToPlaceType,
  Districts,
  Workplaces
} from "../../../entities/Workplaces";

export const workplacePlacesSave = createAsyncThunk(
  'workPlaces/placesSave',
  async (places: ActivePlace[], thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const workplaces = state.workplaces.workplaces;
    const { workingConditionsAtHome, workingConditionsAtFieldWork } = workplaces;
    const currentPlaces = workplaces.places;
    const updatedPlaces = new Set([...currentPlaces, ...places]);

    if (workplaces.id === -1) {
      const res = await agent.post(`${apiUrlV1}/b2c/workplaces/`, {
        body: JSON.stringify({
          places,
          workingConditionsAtHome,
          workingConditionsAtFieldWork
        })
      })
      if (res.status === 201 || res.status === 200) {
        const workplaces: Workplaces = await res.json();
        return workplaces.places;
      }
    }

    const res = await agent.patch(`${apiUrlV1}/b2c/workplaces/${workplaces.id}/`, {
      body: JSON.stringify({
        places: Array.from(updatedPlaces),
      })
    });

    if (res.status === 201 || res.status === 200) {
      const workplaces: Workplaces = await res.json();
      return workplaces.places;
    }
  }
);

export const workplacePlacesRemove = createAsyncThunk(
  'workPlaces/placesRemove',
  async (place: string, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const workplaces = state.workplaces.workplaces;
    const placesToDelete = workplaces.places.filter(item => !item.includes(place))

    const res = await agent.patch(`${apiUrlV1}/b2c/workplaces/${workplaces.id}/`, {
      body: JSON.stringify({
        places: placesToDelete,
      })
    });

    if (res.status === 201 || res.status === 200) {
      const workplaces: Workplaces = await res.json();
      return workplaces.places;
    }
  }
);

export const workplaceAdd = createAsyncThunk(
  'workPlaces/add',
  async (place: PlaceType, thunkApi) => {
    const url = `${apiUrlV1}/b2c/working-conditions/at-home/`;
    const res = await agent.post(url, {
      body: JSON.stringify({
        ...placeToHomeWorkplace(place),
        id: undefined,
      })
    });

    if (res.status === 201) {
      const workingConditionsAtHome = await res.json();
      return workingConditionAtHomeToPlaceType(workingConditionsAtHome)
    }
  }
);

interface WorkplaceUpdateParams {
  place: PlaceType;
  change?: boolean;
}

export const workplaceUpdate = createAsyncThunk(
  'workPlaces/update',
  async ({ place, change = false }: WorkplaceUpdateParams) => {
    const url = `${apiUrlV1}/b2c/working-conditions/at-home/${place.id}/?change=${change}`;
    await agent.put(url, {
      body: JSON.stringify({
        ...placeToHomeWorkplace(place),
        id: place.id,
      })
    });
  }
);

export const workplaceRemove = createAsyncThunk(
  'workPlaces/remove',
  async (place: PlaceType) => {
    const url = `${apiUrlV1}/b2c/working-conditions/at-home/${place.id}`;
    await agent.delete(url, {});
  }
);

export const loadDisrictsByCity = createAsyncThunk(
  'workPlaces/loadDisricts',
  async (id: number) => {
    const res = await agent.get(`${apiUrlV1}/districts-by-city/${id}/`);

    if (res.status === 200) {
      const district: Districts = await res.json();
      return district;
    }
  }
)

export const loadUserDistricts = createAsyncThunk(
  'workPlaces/loadUserDistricts',
  async () => {
    const res = await agent.get(`${apiUrlV1}/b2c/new-working-conditions/`);

    if (res.status === 200) {
      const userDistricts: Districts = await res.json();
      return userDistricts;
    }
  }
)

export const saveUserDistricts = createAsyncThunk(
  'workPlaces/saveUserDistricts',
  async (ids: number[]) => {
    const url = `${apiUrlV1}/b2c/new-working-conditions/`;

    const res = await agent.post(url, {
      body: JSON.stringify({
        districtIds: ids,
      })
    });

    if (res.status === 201) {
      const districtData: Districts = await res.json();
      return districtData;
    }
  }
)