import { Schedule } from "./Schedule";
import { Specialization } from "./Specialization";
import { Workplaces } from "./Workplaces";
import { PlaceType } from "./PlaceType";
import { Point } from "./Point";
import { Country } from "./Country";
import { ImageFile } from "./ImageFile";
import { Subscription } from "./Subscription";
import { ActiveTariffData, Tariff } from "./Rates";

export type Role = 'customer' | 'worker' | 'salon_worker';

export interface City {
  id?: number,
  title: string,
  region: string,
  placeId?: string,
  location: {
    type: 'Point',
    coordinates: Point,
  }
}

export interface PersonalInfo {
  phone: string,
  firstName?: string,
  lastName?: string,
  patronymic?: string,
  locationId?: number,
  location?: PlaceType,
  city?: City,
  cityId?: number,
  slug?: string,
  avatar?: ImageFile,
  avatarId?: number,
  cover?: ImageFile,
  coverId?: number,
  email: string,
  gender: 'male' | 'female',
  aboutMe?: string,
  isClosedBooking: boolean,
  salonRole?: 'salon_administrator' | 'point_administrator' | 'salon_worker'
}

type UserProfile = PersonalInfo & {
  id: number;
  role: Role;
  phone: string;
  referralCode: string,
  referralActions: ReferralActions[]
  personalInfo: PersonalInfo;
  schedule?: Schedule;
  workplaces?: Workplaces;
  specializations: Specialization[],
  subscription?: Subscription,
  activeSubscription?: ActiveTariffData,
  wsbCard?: string,
  wsbExp?: string,
  wsbPaymentExpireDatetime?: string,
  wsbRecurringToken?: string | null,
  isBlocked: boolean,
  wsbIsOnTrial: boolean,
  isDiscountAvailable: boolean,
  rating?: number,
  reviewsCount?: number,
  eventsCount?: number
};

export type CustomerProfile = PersonalInfo & {
  id: number;
  role: 'customer' | 'worker';
  phone: string;
}

export interface ReferralActions {
  id: number,
  actionType: string,
  actionTypeDisplay: string,
  bonusDays: number,
  quantityPerson: null,
  isSeen: false,
  createdAt: string,
  tariff: Tariff,
}

interface PlaceTypeOptions {
  regionTitle: string,
}

export const profileCityToPlaceType = (city: City, options: PlaceTypeOptions) => {
  const { id, title, region, location } = city;
  return {
    id,
    name: title,
    description: `${region} ${options.regionTitle}`,
    region: `${region} ${options.regionTitle}`,
    pos: location.coordinates,
    active: true,
    kind: 'locality',
    cityId: id,
    country: Country.Belarus,
  } as PlaceType;
};

export default UserProfile;
