import {Point} from "./Point";
import {PlaceType} from "./PlaceType";
import {Country} from "./Country";

export type ActivePlace =  'atHome' | 'fieldWork' | 'atOnline';

export interface WorkingConditionsAtFieldWork {
  id?: number,
  rate: {
    pricePerKilometer: number,
    markup: number,
  },
  fieldWorkRegion: {
    type: string,
    polygon: {
      type: string,
      coordinates: Point[][]
    },
    address: string,
  }
}

export interface WorkingConditionsAtHome {
  id: number,
  name: string,
  address: string,
  center: {
    type: string,
    coordinates: Point
  },
  isActive: boolean,
  flatNumber: string;
}

export interface Workplaces {
  id: number,
  places: ActivePlace[],
  workingConditionsAtHome: WorkingConditionsAtHome[],
  workingConditionsAtFieldWork?: WorkingConditionsAtFieldWork,
  newWorkingConditionsAtFieldWork?: newWorkingConditionsAtFieldWork,
  eventOnlineCount?: number,
  eventFieldWorkCount?: number,
  eventAtHomeCount?: number,
}

export interface newWorkingConditionsAtFieldWork {
  wholeCity: boolean,
  districtsData: District[],
}

export interface Districts {
  districtsData: District[],
}

export interface District {
  id: number,
  name: string,
}

export const workingConditionAtHomeToPlaceType = (i: WorkingConditionsAtHome, idx?: number) => {
  const place: PlaceType = {
    id: idx ? idx : i.id,
    name: i.name,
    description: i.address,
    pos: i.center.coordinates,
    country: Country.Belarus,
    kind: 'house',
    active: i.isActive,
    flatNumber: i.flatNumber,
  };
  return place;
}