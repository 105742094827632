import {createAsyncThunk} from "@reduxjs/toolkit";
import {agent} from "../../../api";
import {apiUrlV1} from "../../../config/config";
import {Client} from "../../../entities/Client";

interface ClientsLoadParams {
  queryText?: string,
}

export const loadClients = createAsyncThunk(
  'clients/load',
  async (params: ClientsLoadParams, thunkApi): Promise<Client[]> => {
    const {queryText} = params;

    const res = await agent.get(
      `${apiUrlV1}/b2c/clients/`
        + ((queryText && queryText?.length > 0 && `?search=${queryText}`) || '')
    );

    if (res.status === 200) {
      const body = await res.json();
      return body.results as Client[];
    }

    return [];
  }
);

export const loadClientById = createAsyncThunk(
  'clients/loadClientById',
  async (id: number) => {
    const res = await agent.get(`${apiUrlV1}/b2c/clients/${id}/`);

    if (res.status === 200) {
      const body = await res.json();
      return body as Client;
    }

    throw Error('User not found');
  }
);

export const addClient = createAsyncThunk(
  'clients/add',
  async (client: Client, thunkApi): Promise<Client> => {
    const res = await agent.post(`${apiUrlV1}/b2c/clients/`, {
      body: JSON.stringify(client),
    });

    if (res.status === 201) {
      const body = await res.json();
      return body as Client;
    } else if (res.status === 400) {
      throw Error('Client cannot be added: phone already exists');
    }

    throw Error(`Client cannot be added: unknown reason`);
  }
);

export const updateClient = createAsyncThunk(
  'clients/update',
  async (client: Client, thunkApi): Promise<Client> => {
    const res = await agent.put(`${apiUrlV1}/b2c/clients/${client.id}/`, {
      body: JSON.stringify(client),
    });

    if (res.status === 200) {
      const body = await res.json();
      return body as Client;
    }

    throw Error(`Client cannot be updated: unknown reason`);
  }
);

export const removeClient = createAsyncThunk(
  'clients/remove',
  async (client: Client, thunkApi) => {
    const res = await agent.delete(`${apiUrlV1}/b2c/clients/${client.id}/`);

    if (res.status === 204) {
      return;
    }

    throw Error(`Couldn't delete that client`);
  }
);

export const restoreClient = createAsyncThunk(
  'clients/restore',
  async (client: Client, thunkApi) => {
    const res = await agent.post(`${apiUrlV1}/b2c/clients/`, {
      body: JSON.stringify(client),
    });

    if (res.status === 201) {
      const body = await res.json();
      return {prev: client, next: body as Client};
    } else if (res.status === 400) {
      throw Error('Client cannot be added: phone already exists');
    }

    throw Error(`Client cannot be added: unknown reason`);
  }
);