import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../../config/config";
import UserProfile from "../../../entities/UserProfile";
import { agent } from "../../../api";
import { Specialization } from "../../../entities/Specialization";
import { Workplaces } from "../../../entities/Workplaces";
import { IndividualWeekdays, Schedule } from "../../../entities/Schedule";
import { Subscription } from "../../../entities/Subscription";
import { SalonInfoReq } from "src/entities/Salon";

export const individualWeekdays = createAsyncThunk(
  'auth/individual-weekdays',
  async () => {
    const res = await agent.get(`${apiUrlV1}/schedule/individual-weekdays/`)
    const result = await res.json()

    return result as IndividualWeekdays
  }
)

export const fetchUserProfile = createAsyncThunk(
  'auth/fetchUserProfile',
  async (_, thunkApi) => {
    const userProfileRes = await agent.get(`${apiUrlV1}/profiles/me/`);

    if (userProfileRes.status !== 200) {
      return null;
    }

    const userProfile: UserProfile = await userProfileRes.json();

    await Promise.all([
      agent.get(`${apiUrlV1}/catalog/user-specializations/`).then(res => res.json()).then(body => {
        userProfile.specializations = body.results;
      }),
      agent.get(`${apiUrlV1}/b2c/workplaces/`).then(res => res.json()).then(body => {
        userProfile.workplaces = body.results[0];
      }),
      agent.get(`${apiUrlV1}/schedules/`).then(res => res.json()).then(body => {
        userProfile.schedule = body.results.filter((i: Schedule) => i.isActive)[0];
      }),
      agent.get(`${apiUrlV1}/profiles/tariffs/me`).then(res => res.json()).then(body => {
        userProfile.activeSubscription = body;
      }),
      agent.get(`${apiUrlV1}/payment/webpay/info/`).then((res) => {
        return res.json();
      }).then((subscription: Subscription) => {
        userProfile.subscription = subscription;
      }).catch(() => {
        return undefined;
      })
    ]);
    if (userProfile.schedule && userProfile.schedule.type === 'individual') {
      thunkApi.dispatch(individualWeekdays());
    }

    return userProfile;
  }
);

export const fetchUserSpecializations = createAsyncThunk(
  'auth/fetchUserSpecializations',
  async (data, thunkApi) => {
    const res = await agent.get(`${apiUrlV1}/catalog/user-specializations/`);

    if (res.status === 200) {
      const resBody = await res.json();
      const specializations: Specialization[] = resBody.results;
      return specializations;
    }
  }
);

export const fetchUserWorkplaces = createAsyncThunk(
  'auth/fetchUserWorkplaces',
  async (data, thunkApi) => {
    const res = await agent.get(`${apiUrlV1}/b2c/workplaces/`);

    if (res.status === 200) {
      const resBody = await res.json();
      const workplaces: Workplaces = resBody.results[0];
      return workplaces;
    }
  }
);

export const fetchSalonProfile = createAsyncThunk(
  'salon/salon-info',
  async () => {
    const res = await agent.get(`${apiUrlV1}/salon/salon-info/`);

    if (res.status === 200) {
      const body = await res.json();
      return body.results[0] as SalonInfoReq
    }

    throw Error("Couldn't load salon list");
  }
)

export const userLogOut = createAsyncThunk(
  'auth/userLogOut',
  async (data, thunkApi) => {
    thunkApi.dispatch({ type: 'logout' });
    localStorage.removeItem('token');
  }
);

export const deleteUserProfile = createAsyncThunk(
  'auth/deleteUserProfile',
  async () => {
    const res = await agent.delete(`${apiUrlV1}/profiles/me/destroy/`)

    if (!res.ok) {
      throw Error("Couldn't delete profile");
    }
    localStorage.removeItem('token');
  }
);

export const testFetchUserProfile = createAsyncThunk(
  'auth/testFetchUserProfile',
  async () => {
    const userProfileRes = await agent.get(`${apiUrlV1}/profiles/me/`);

    if (userProfileRes.status !== 200) {
      return null;
    }

    const userProfile: UserProfile = await userProfileRes.json();

    return userProfile;
  }
);

export const submitReferralActions = createAsyncThunk(
  'auth/submitReferralActions',
  async (type: string, thunkApi) => {
    const url = `${apiUrlV1}//profiles/me/update-referral-action-seen/`;
    const res = await agent.post(url, {
      body: JSON.stringify({ actionType: `${type}`})
    });
    if (res.status === 200 || res.status === 201) {
      thunkApi.dispatch(fetchUserProfile());
      return (await res.json());
    }

    throw Error('error while submitting referralActions');
  }
);