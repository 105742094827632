import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  loadSystemSpecializations,
  loadSystemServices,
  loadUserSpecialization,
  loadUserSpecializations,
  deleteUserService,
  searchUserServices,
  searchUserSpecializations,
  updateUserService,
  updateUserSpecializations,
  loadUserServices
} from "./thunks";
import { Service, Specialization, SpecializationOverview, SingleService } from "../../../entities/Specialization";
import { RootState } from "../index";

interface UserSpecializationState {
  userSpecialization?: Specialization,
  systemSpecialization?: Specialization,
  loading: boolean,
  adding: boolean,
  updating: boolean,
  error: boolean,
}

interface ServicesState {
  systemSpecializationsState: {
    loading: boolean,
    paramsHash: string,
    systemSpecializations: SpecializationOverview[],
  },
  systemServicesState: {
    loading: boolean,
    paramsHash: string,
    systemServices: SingleService[],

  },
  userSpecializationsState: {
    loading: boolean,
    userSpecializations: SpecializationOverview[],
    updating: boolean,
  },
  userSpecializationStates: Record<number, UserSpecializationState>,
  servicesSearchState: {
    specializations: Specialization[],
    services: Service[],
    loading: boolean,
  },
  userSpecialisationState: {
    specialization: Specialization | null,
  },
  userServicesState: {
    isSelectedService: boolean,
  },
  fromEvents: boolean,
}

const initialState: ServicesState = {
  systemSpecializationsState: {
    loading: false,
    paramsHash: '',
    systemSpecializations: [],
  },
  systemServicesState: {
    loading: false,
    paramsHash: '',
    systemServices: [],
  },
  userSpecializationsState: {
    loading: false,
    updating: false,
    userSpecializations: [],
  },
  userSpecializationStates: {},
  servicesSearchState: {
    specializations: [],
    services: [],
    loading: false,
  },
  userSpecialisationState: {
    specialization: null,
  },
  userServicesState: {
    isSelectedService: false,
  },
  fromEvents: false,
};

const servicesSlice = createSlice({
  name: 'servicesReducer',
  initialState,
  reducers: {
    addSpecialisationData: (state, action: PayloadAction<Specialization | null>) => {
      if (action.payload) {
        state.userSpecialisationState.specialization = action.payload;
      } else {
        state.userSpecialisationState.specialization = null;
      }
    },
    addServiceData: (state, action: PayloadAction<boolean>) => {
      state.userServicesState.isSelectedService = action.payload;
    },
    addSystemServiceData: (state, action) => {
      state.systemServicesState.systemServices = action.payload;
    },
    setFromEvents(state, action) {
      state.fromEvents = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadSystemSpecializations.pending, (state, action) => {
      state.systemSpecializationsState.loading = true;
      state.systemSpecializationsState.paramsHash = JSON.stringify(action.meta.arg);
    });

    builder.addCase(loadSystemSpecializations.fulfilled, (state, action) => {
      state.systemSpecializationsState.loading = false;
      const hashEquals = state.systemSpecializationsState.paramsHash === JSON.stringify(action.meta.arg);
      if (hashEquals) {
        state.systemSpecializationsState.systemSpecializations = action.payload;
      }
    });

    builder.addCase(loadSystemServices.pending, (state, action) => {
      state.systemServicesState.loading = true;
      state.systemServicesState.paramsHash = JSON.stringify(action.meta.arg);
    });

    builder.addCase(loadSystemServices.fulfilled, (state, action) => {
      state.systemServicesState.loading = false;
      const { offset } = action.meta.arg;
      const hashEquals = state.systemServicesState.paramsHash === JSON.stringify(action.meta.arg);
      if (hashEquals) {
        const existingServices = state.systemServicesState.systemServices;
        const newServices = action.payload;
        const updatedServices = offset ? [...existingServices, ...newServices] : newServices;
        state.systemServicesState.systemServices = updatedServices;
      }
    });

    builder.addCase(loadUserSpecializations.pending, (state, action) => {
      state.userSpecializationsState.loading = true;
    });

    builder.addCase(loadUserSpecializations.fulfilled, (state, action) => {
      state.userSpecializationsState.loading = false;
      state.userSpecializationsState.userSpecializations = action.payload;

      for (const s of action.payload) {
        state.userSpecializationStates[s.id] = {
          loading: true,
          updating: false,
          error: false,
          adding: false,
        };
      }
    });

    builder.addCase(loadUserSpecialization.pending, (state, action) => {
      const { userSpecializationId } = action.meta.arg;
      state.userSpecializationStates[userSpecializationId].loading = true;
    });
    builder.addCase(loadUserSpecialization.fulfilled, (state, action) => {
      const { userSpecializationId } = action.meta.arg;
      const { userSpecialization, systemSpecialization } = action.payload;
      state.userSpecializationStates[userSpecializationId].loading = false;
      state.userSpecializationStates[userSpecializationId].userSpecialization = userSpecialization;
      state.userSpecializationStates[userSpecializationId].systemSpecialization = systemSpecialization;
    });

    builder.addCase(deleteUserService.fulfilled, (state, action) => {
      const deletedServiceId = action.meta.arg;
      state.userSpecializationsState.userSpecializations = state.userSpecializationsState.userSpecializations.map(specialization => {
        if (specialization.services) {
          specialization.services = specialization.services.filter(service => service.id !== deletedServiceId);
        }
        return specialization;
      });
    });
    builder.addCase(updateUserSpecializations.pending, (state) => {
      state.userSpecializationsState.loading = true;
      state.userSpecializationsState.updating = true;
      state.userSpecializationsState.userSpecializations = [];
    });
    builder.addCase(updateUserSpecializations.fulfilled, (state, action) => {
      state.userSpecializationsState.updating = false;
      // state.userSpecializationsState.userSpecializations = action.payload;
    });

    builder.addCase(searchUserServices.pending, (state) => {
      state.servicesSearchState.loading = true;
    });
    builder.addCase(searchUserServices.fulfilled, (state, action) => {
      state.servicesSearchState.loading = false;
      state.servicesSearchState.services = action.payload;
    });
    builder.addCase(searchUserServices.rejected, (state) => {
      state.servicesSearchState.loading = false;
    });

    builder.addCase(searchUserSpecializations.pending, (state) => {
      state.servicesSearchState.loading = true;
    });
    builder.addCase(searchUserSpecializations.fulfilled, (state, action) => {
      state.servicesSearchState.loading = false;
      state.servicesSearchState.specializations = action.payload;
    });
    builder.addCase(searchUserSpecializations.rejected, (state) => {
      state.servicesSearchState.loading = false;
    });
  },
});

export const servicesReducer = servicesSlice.reducer;
export const { addSpecialisationData, addServiceData, addSystemServiceData, setFromEvents } = servicesSlice.actions;

export const selectSystemSpecializationsState = (state: RootState) => {
  return state.services.systemSpecializationsState;
}

export const selectSystemSpecializations = (state: RootState) => {
  return state.services.systemSpecializationsState.systemSpecializations;
}

export const selectSystemServicesState = (state: RootState) => {
  return state.services.systemServicesState;
}

export const selectUserSpecializationsState = (state: RootState) => {
  return state.services.userSpecializationsState;
}

export const selectUserSpecializations = (state: RootState) => {
  return state.services.userSpecializationsState.userSpecializations;
}

export const selectUserSpecializationState = (userSpecializationId: number) => (state: RootState) => {
  return state.services.userSpecializationStates[userSpecializationId];
}

export const selectServicesSearchState = (state: RootState) => {
  return state.services.servicesSearchState;
}

export const selectUserSpecialisationState = (state: RootState) => {
  return state.services.userSpecialisationState;
}

export const selectUserServicesState = (state: RootState) => {
  return state.services.userServicesState;
}