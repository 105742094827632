import {IconButtonProps} from "@material-ui/core";
import {fromYandexGeoObject, PlaceKind, PlaceType} from "../../entities/PlaceType";
import {useCallback, useState} from "react";
import {yandexMapsApiKey} from "../../config/config";
import {GeoPositionButton} from "../GeoPositionButton/GeoPositionButton";

interface PlaceButtonProps {
  iconButtonProps?: IconButtonProps,
  onPlaceChange: (pos: PlaceType) => void,
  kind?: PlaceKind
}

export function PlaceButton({iconButtonProps, onPlaceChange, kind}: PlaceButtonProps) {
  const [loading, loadingSet] = useState<boolean>(false);
  const fetchPlace = useCallback((pos: GeolocationPosition) => {
    loadingSet(true);

    fetch(
      `https://geocode-maps.yandex.ru/1.x/` +
      `?apikey=${yandexMapsApiKey}` +
      `&geocode=${pos.coords.longitude},${pos.coords.latitude}` +
      `&format=json` +
      (kind ? `&kind=${kind}` : '')
    ).then(
      res => res.json()
    ).then(res => {
      const list: any[] = res?.response
        ?.GeoObjectCollection
        ?.featureMember
        .map((i: any) => i.GeoObject);
      const place: PlaceType = fromYandexGeoObject(list[0]);

      onPlaceChange(place);
      loadingSet(false);
    });

  }, [onPlaceChange, loadingSet, kind]);

  return (
    <GeoPositionButton
      iconButtonProps={iconButtonProps}
      onPositionChange={fetchPlace}
      loading={loading}
    />
  );
}