import {createAsyncThunk} from "@reduxjs/toolkit";
import {agent} from "../../../api";
import {apiUrlV1} from "../../../config/config";
import {Plan, Subscription} from "../../../entities/Subscription";
import {fetchUserProfile} from "../auth/profileLoad";
import {makeUserLoading} from "../auth/authStore";

export const loadSubscription = createAsyncThunk(
  'subscription/loadSubscription',
  async () => {
    return agent.get(`${apiUrlV1}/payment/webpay/info/`).then((res) => {
      return res.json();
    }).then((body: Subscription) => {
      return body;
    });
  }
);

export const unbindSubscription = createAsyncThunk(
  'subscription/unbindSubscription',
  (a, thunkApi) => {
    thunkApi.dispatch(makeUserLoading());
    return agent.post(`${apiUrlV1}/payment/webpay/unbind/`, {
      body: JSON.stringify({})
    }).then(res => {
      return res.json();
    }).then(body => {
      const {status} = body;

      thunkApi.dispatch(fetchUserProfile());
    });
  }
)

export const changeSubscription = createAsyncThunk(
  'subscription/changeSubscription',
  (plan: Plan, thunkApi) => {
    thunkApi.dispatch(makeUserLoading());

    return agent.post(`${apiUrlV1}/payment/webpay/change-subscription/`, {
      body: JSON.stringify({
        wsb_subscription: plan.type,
      })
    }).then(res => {
      return res.json();
    }).then(body => {
      const {status} = body;

      thunkApi.dispatch(fetchUserProfile());
    });
  }
)

