import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { SalonServiceEvent, SalonServices, SalonWorker, WorkersAndEvents } from "src/entities/Salon";
import { WorkloadByDays } from "src/entities/Workload";
import { RootState } from "..";
import { StatusType } from "./salonStore";
import { getServicesPoint, getWorkersByServices, getWorkersEvents, workLoadStat } from "./thunks";

export type NavType = 'home' | 'addOrder' | 'staff'

interface DashboardState {
  loading: boolean,
  saving: boolean,
  modalClient: boolean,
  editStatus: StatusType,
  activeEvent?: SalonServiceEvent,
  activeWorker: SalonWorker | null,
  pointServices: SalonServices[],
  workers: WorkersAndEvents[] | [],
  workersEvents: SalonWorker[] | [],
  panel: NavType,
  dateDashboard: string,
  workloadByDays: WorkloadByDays
}

const startDate = new Date()

const initialState: DashboardState = {
  loading: false,
  saving: false,
  modalClient: false,
  editStatus: 'create',
  activeWorker: null,
  pointServices: [],
  workers: [],
  panel: 'home',
  workersEvents: [],
  dateDashboard: format(startDate, 'yyyy-MM-dd'),
  workloadByDays: {},
};

export const dashboardSlice = createSlice({
  name: 'salons',
  initialState,
  reducers: {
    setActiveWorker: (state: DashboardState, action: PayloadAction<SalonWorker | null>) => {
      state.activeWorker = action.payload
    },
    updateEvent: (state: DashboardState, action: PayloadAction<SalonServiceEvent>) => {
      state.panel = 'addOrder'
      state.editStatus = 'update'
      state.activeEvent = action.payload
    },
    onClickPanel: (state: DashboardState, action: PayloadAction<NavType>) => {
      state.panel = action.payload
    },
    setDateDashboard: (state: DashboardState, action: PayloadAction<string>) => {
      state.dateDashboard = action.payload
    },
    openClientModal: (state: DashboardState, action: PayloadAction<boolean>) => {
      state.modalClient = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServicesPoint.fulfilled, (state: Draft<DashboardState>, action) => {
      state.pointServices = action.payload
    })

    builder.addCase(getWorkersEvents.fulfilled, (state: Draft<DashboardState>, action) => {
      state.workers = action.payload
    })

    builder.addCase(getWorkersByServices.fulfilled, (state: Draft<DashboardState>, action) => {
      state.workersEvents = action.payload
    })

    builder.addCase(workLoadStat.fulfilled, (state: Draft<DashboardState>, action) => {
      const workload = action.payload;

      state.workloadByDays = {};
      state.workloadByDays = workload.reduce((acc, dayWorkload) => {
        acc[dayWorkload.date] = dayWorkload;
        return acc;
      }, state.workloadByDays);
    })
  }
})

export const dashboardReducer = dashboardSlice.reducer

export const { setActiveWorker, onClickPanel, setDateDashboard, updateEvent, openClientModal } = dashboardSlice.actions

export const selectServicesPoint = (state: RootState) => state.dashboard.pointServices
export const selectWorkersEvents = (state: RootState) => state.dashboard.workers