import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../../api";
import { apiUrlV1 } from "../../../config/config";

interface Features {
    featureSlugs: string[];
  }

  export const loadAllTariffs = createAsyncThunk(
    'tariff/loadAlltariffs',
    async () => {
      try {
        const res = await agent.get(`${apiUrlV1}/profiles/tariffs/`);
        if (res.status === 200) {
          const data = await res.json();
          return data;
        } else {
          throw new Error('API request failed');
        }
      } catch (error) {
        throw new Error('An error occurred');
      }
    }
  );

  export const loadAllFeatures = createAsyncThunk(
    'tariff/loadAllFeatures',
    async () => {
      try {
        const res = await agent.get(`${apiUrlV1}/profiles/features/`);
        if (res.status === 200) {
          const data = await res.json();
          return data;
        } else {
          throw new Error('API request failed');
        }
      } catch (error) {
        throw new Error('An error occurred');
      }
    }
  );

export const loadFeatures = createAsyncThunk(
    'tariff/loadFeatures',
    async () => {
      try {
        const res = await agent.get(`${apiUrlV1}/profiles/tariffs/me/features`);
        if (res.status === 200) {
          const data: Features = await res.json();
          return data.featureSlugs;
        } else {
          throw new Error('API request failed');
        }
      } catch (error) {
        throw new Error('An error occurred');
      }
    }
  );

  export const loadCurrentTariff = createAsyncThunk(
    'tariff/loadTariff',
    async () => {
      try {
        const res = await agent.get(`${apiUrlV1}/profiles/tariffs/me`);
        if (res.status === 200) {
          const data = await res.json();
          return data;
        } else {
          throw new Error('API request failed');
        }
      } catch (error) {
        throw new Error('An error occurred');
      }
    }
  );