import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { b2cTheme, useGlobalStyles } from "../theme/B2CTheme";
import store, { useAppDispatch, useAppSelector } from "./store";
import '../utils/validation';
import { changeUserToken, selectAuthState, selectUserProfile } from "./store/auth/authStore";
import { AuthWizard } from "./pages/Auth";
import { fetchSalonProfile, fetchUserProfile } from "./store/auth/profileLoad";
import { ProfileContext } from './store/auth/userProfileContext';
import { enableMapSet } from "immer";
import { setToken } from "../api";
import { IsMobileContext, useIsMobileContextValue } from '../hooks/useIsMobile';
import { currentTariffState, setTrialDate, setActiveDate, allTariffState, allFeaturesState } from "src/masters/store/tariffs/tariffsStore";
import {loadAllTariffs, loadAllFeatures, loadFeatures} from "./store/tariffs/thunks";

const SalonsDashboardRouter = React.lazy(async () => (await import('./navigation/SalonsDashboardRouter')));
const MastersDashboardRouter = React.lazy(async () => (await import('./navigation/MastersDashboardRouter')));
const MasterSalonDashboardRouter = React.lazy(async () => (await import('./navigation/MasterSalonDashboardRouter')));
const AdminPointRouter = React.lazy(async () => (await import('./navigation/AdminPointRouter')));

enableMapSet();

const RouterOnAuth = () => {
  const authState = useAppSelector(selectAuthState);
  const userProfile = useAppSelector(selectUserProfile);
  const tariffs = useAppSelector(allTariffState);
  const features = useAppSelector(allFeaturesState);
  const dispatch = useAppDispatch();
  const {expireDatetime} = useAppSelector(currentTariffState);

  useEffect(() => {
    if (userProfile && 
        (userProfile.wsbIsOnTrial || userProfile.activeSubscription?.activeTariff.tariff.slug !== 'base')) {
          
      const getDayDeclension = (daysDifference: number) => {
        if (daysDifference > 10 && [11, 12, 13, 14].includes(daysDifference%100)) return 'дней';
        const last_num = daysDifference%10;
        if (last_num == 1) return 'день';
        if ([2,3,4].includes(last_num)) return 'дня';
        if ([5,6,7,8,9, 0].includes(last_num)) return 'дней';
      };
    
      const setActiveDateAndTrial = () => {
        const tariffDate = new Date(expireDatetime);
        const currentDate = new Date();
        const day = tariffDate.getDate();
        let month = tariffDate.toLocaleString('default', { month: 'long' });
        month = month.replace(/ь$/, 'я');
        if (month === 'март') {
          month = 'марта';
        }
        const activeDate = `${day} ${month}`;
        dispatch(setActiveDate(activeDate));
    
        const timeDifference = Math.abs(tariffDate.getTime() - currentDate.getTime());
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        const trialDate = `${daysDifference} ${getDayDeclension(daysDifference)}`;
    
        dispatch(setTrialDate(trialDate));
      };
    
      setActiveDateAndTrial();
    }
  }, [dispatch, expireDatetime]);

  useEffect(() => {
    if (authState.token) dispatch(fetchUserProfile());
  }, [authState.token, dispatch]);

  useEffect(() => {
    if (authState.token) {
      if (!!userProfile && userProfile.role === 'worker' && userProfile.salonRole === 'salon_administrator') {
        dispatch(fetchSalonProfile())
      }
      if (!!userProfile && userProfile.role === 'salon_worker') {
        dispatch(fetchSalonProfile())
      }
    }
  }, [userProfile, authState.token, dispatch])

  useEffect(() => {
    if (tariffs.results.length < 1 && userProfile) {
      dispatch(loadAllTariffs());
    }
  }, [dispatch, userProfile]);

  useEffect(() => {
    if (features.results.length < 1 && userProfile) {
      dispatch(loadAllFeatures());
    }
    if (userProfile) {
      dispatch(loadFeatures());
    }
  }, [dispatch, userProfile]);

  if (!authState.profile && authState.loading) {
    return <div />;
  }

  return (
    <>
      {!!userProfile && userProfile.role === 'worker' && !userProfile.salonRole && (
        <React.Suspense fallback={<div />}>
          <div>
            {userProfile && (
              <ProfileContext.Provider value={userProfile}>
                <MastersDashboardRouter />
              </ProfileContext.Provider>
            )}
          </div>
        </React.Suspense>
      )}
      {!!userProfile && userProfile.salonRole === 'salon_administrator' && (
        <React.Suspense fallback={<div />}>
          <div>
            {userProfile && (
              <ProfileContext.Provider value={userProfile}>
                <SalonsDashboardRouter />
              </ProfileContext.Provider>
            )}
          </div>
        </React.Suspense>
      )}
      {!!userProfile && userProfile.role === 'salon_worker' && userProfile.salonRole === 'point_administrator' && (
        <React.Suspense fallback={<div />}>
          <div>
            {userProfile && (
              <ProfileContext.Provider value={userProfile}>
                <AdminPointRouter />
              </ProfileContext.Provider>
            )}
          </div>
        </React.Suspense>
      )}
      {!!userProfile && userProfile.role === 'salon_worker' && userProfile.salonRole === 'salon_worker' && (
        <React.Suspense fallback={<div />}>
          <div>
            {userProfile && (
              <ProfileContext.Provider value={userProfile}>
                <MasterSalonDashboardRouter />
              </ProfileContext.Provider>
            )}
          </div>
        </React.Suspense>
      )}
      {!userProfile && (
        <div>
          <React.Suspense fallback={<div />}>
            <AuthWizard
              role={'worker'}
              onUserAuth={(token, profile) => {
                localStorage.setItem('token', token);
                setToken(token);
                dispatch(changeUserToken(token));
                // dispatch(changeUserProfile(profile))
                dispatch(fetchUserProfile);
              }}
            />
          </React.Suspense>
        </div>
      )}
    </>
  );
}

export const MastersDashboardApp = () => {
  const isMobileContextValue = useIsMobileContextValue();
  useGlobalStyles();
  return (
    <ThemeProvider theme={b2cTheme}>
      <CssBaseline />
      <Provider store={store}>
        <IsMobileContext.Provider value={isMobileContextValue}>
          <RouterOnAuth />
        </IsMobileContext.Provider>
      </Provider>
    </ThemeProvider>
  );
}

export default MastersDashboardApp;
