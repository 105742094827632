import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  addCommonEvent,
  addServiceEvent,
  cancelServiceEvent,
  clientDidntCome,
  loadMasterEventById,
  loadMasterEvents,
  loadServiceEvents, loadUserScheduleIntervals, loadUserWorkload,
  saveMasterEvent
} from "./thunks";
import { RootState } from "../index";
import { CommonEvent, MasterEvent, ServiceEvent, ServiceEventState } from "../../../entities/Event";
import { IntervalsByDays, ScheduleIntervals, WorkloadByDays } from "../../../entities/Workload";
import { Client } from "src/entities/Client";

interface ServiceEventPayload {
  field: keyof ServiceEventState;
  value: any;
}

interface EventsStore {
  serviceEventState: {
    loading: boolean,
    adding: boolean,
    added: boolean,
    error: boolean,
    serviceEvents: ServiceEvent[],
  },
  commonEventState: {
    loading: boolean,
    adding: boolean,
    added: boolean,
    error: boolean,
    commonEvents: CommonEvent[],
  },
  masterEventsState: {
    loading: boolean,
    masterEvents: MasterEvent[],
  },
  eventEditState: {
    masterEvent: MasterEvent | undefined,
    saved: boolean,
    canceled: boolean,
    saving: boolean,
    loading: boolean,
    canceling: boolean,
  },
  workloadByDays: WorkloadByDays,
  scheduleIntervals: IntervalsByDays;
  serviceEvent: ServiceEventState;
}

const initialServiceEventState: EventsStore['serviceEventState'] = {
  loading: false,
  adding: false,
  added: false,
  error: false,
  serviceEvents: [],
};

const initialCommonEventState: EventsStore['commonEventState'] = {
  loading: false,
  adding: false,
  added: false,
  error: false,
  commonEvents: [],
};

const initialMasterEventsState: EventsStore['masterEventsState'] = {
  loading: false,
  masterEvents: [],
};

const initialServiceEvent: EventsStore['serviceEvent'] = {
  title: '',
  client: null,
  place: '',
  startDate: '',
  endDate: '',
  services: [],
  comment: '',
  notify: false,
  address: '',
  flatNumber: '',
  isFirstEvent: false,
  breakDuration: 0,
  discount: 0,
  discountType: 'percentage',
}

const initialState: EventsStore = {
  serviceEventState: initialServiceEventState,
  commonEventState: initialCommonEventState,
  masterEventsState: initialMasterEventsState,
  eventEditState: {
    masterEvent: undefined,
    loading: false,
    saved: false,
    canceled: false,
    saving: false,
    canceling: false,
  },
  workloadByDays: {},
  scheduleIntervals: {},
  serviceEvent: initialServiceEvent,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    initServiceEventAdd: (state) => {
      state.serviceEventState = initialServiceEventState;
    },
    initEventEditState: (state) => {
      state.eventEditState = initialState.eventEditState;
    },
    setServiceEvent(state, action: PayloadAction<ServiceEventPayload>) {
      const { field, value } = action.payload;
      (state.serviceEvent as any)[field] = value;
    },
    resetServiceEvent: (state) => {
      state.serviceEvent = initialServiceEvent;
    },
  },
  extraReducers: builder => {
    builder.addCase(addServiceEvent.pending, (state) => {
      state.serviceEventState.adding = true;
      state.serviceEventState.added = false;
    });
    builder.addCase(addServiceEvent.fulfilled, (state) => {
      state.serviceEventState.adding = false;
      state.serviceEventState.error = false;
      state.serviceEventState.added = true;
    });
    builder.addCase(addServiceEvent.rejected, (state) => {
      state.serviceEventState.adding = false;
      state.serviceEventState.error = true;
    });

    builder.addCase(addCommonEvent.pending, (state) => {
      state.commonEventState.adding = true;
      state.commonEventState.added = false;
    });
    builder.addCase(addCommonEvent.fulfilled, (state) => {
      state.commonEventState.adding = false;
      state.commonEventState.error = false;
      state.commonEventState.added = true;
    });
    builder.addCase(addCommonEvent.rejected, (state) => {
      state.commonEventState.adding = false;
      state.commonEventState.error = true;
    });

    builder.addCase(loadServiceEvents.pending, (state) => {
      state.serviceEventState.loading = true;
      state.serviceEventState.serviceEvents = [];
    });
    builder.addCase(loadServiceEvents.fulfilled, (state, thunkApi) => {
      state.serviceEventState.loading = false;
      state.serviceEventState.serviceEvents = thunkApi.payload;
    });

    builder.addCase(loadMasterEvents.pending, (state) => {
      state.masterEventsState.loading = true;
      state.masterEventsState.masterEvents = [];
    });
    builder.addCase(loadMasterEvents.fulfilled, (state, thunkApi) => {
      state.masterEventsState.loading = false;
      state.masterEventsState.masterEvents = thunkApi.payload;
    });

    builder.addCase(loadMasterEventById.pending, (state) => {
      state.eventEditState.loading = true;
      state.eventEditState.masterEvent = undefined;
    });
    builder.addCase(loadMasterEventById.fulfilled, (state, thunkApi) => {
      state.eventEditState.loading = false;
      state.eventEditState.masterEvent = thunkApi.payload;
    });

    builder.addCase(saveMasterEvent.pending, (state) => {
      state.eventEditState.saved = false;
      state.eventEditState.saving = true;
    });
    builder.addCase(saveMasterEvent.fulfilled, (state, thunkApi) => {
      state.eventEditState.saved = true;
      state.eventEditState.saving = false;
    });

    builder.addCase(cancelServiceEvent.pending, (state) => {
      state.eventEditState.canceled = false;
      state.eventEditState.canceling = true;
    });
    builder.addCase(cancelServiceEvent.fulfilled, (state, thunkApi) => {
      state.eventEditState.canceled = true;
      state.eventEditState.canceling = false;
      state.masterEventsState.masterEvents = state.masterEventsState.masterEvents.filter(event => event.id !== thunkApi.meta.arg.id)
    });

    builder.addCase(clientDidntCome.pending, (state) => {
      state.eventEditState.canceled = false;
      state.eventEditState.canceling = true;
    })
    builder.addCase(clientDidntCome.fulfilled, (state, thunkApi) => {
      state.eventEditState.canceled = true;
      state.eventEditState.canceling = false;
      state.masterEventsState.masterEvents = state.masterEventsState.masterEvents.filter(event => event.id !== thunkApi.meta.arg)
    });

    builder.addCase(loadUserWorkload.pending, (state) => {
      state.workloadByDays = {};
    });
    builder.addCase(loadUserWorkload.fulfilled, (state, action) => {
      const workload = action.payload;

      state.workloadByDays = {};
      state.workloadByDays = workload.reduce((acc, dayWorkload) => {
        acc[dayWorkload.date] = dayWorkload;
        return acc;
      }, state.workloadByDays);
    });

    builder.addCase(loadUserScheduleIntervals.fulfilled, (state, action) => {
      const scheduleIntervals = action.payload as ScheduleIntervals[];
  
      const intervalsByDate: { [date: string]: ScheduleIntervals['intervals'] } = {};
      for (const interval of scheduleIntervals) {
          intervalsByDate[interval.date] = interval.intervals;
      }

      state.scheduleIntervals = {
          ...state.scheduleIntervals,
          ...intervalsByDate,
      };
  });
  },
});

export const { reducer: eventsReducer } = eventsSlice;

export const { initEventEditState , setServiceEvent, resetServiceEvent } = eventsSlice.actions;

export const selectServiceEventState = (state: RootState) => {
  return state.events.serviceEventState;
}

export const selectServiceEvent = (state: RootState) => {
  return state.events.serviceEvent;
}

export const selectCommonEventState = (state: RootState) => {
  return state.events.commonEventState;
}

export const selectMasterEventState = (state: RootState) => {
  return state.events.masterEventsState;
}

export const selectEventEditState = (state: RootState) => {
  return state.events.eventEditState;
}

export const selectWorkloadByDays = (state: RootState) => {
  return state.events.workloadByDays;
}

export const selectScheduleIntervals = (state: RootState) => {
  return state.events.scheduleIntervals;
}
