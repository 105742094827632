import { createAsyncThunk } from "@reduxjs/toolkit";
import { Consumable, ConsumableRequest } from "src/entities/Consumable";
import { agent, encodeGetParams } from "../../../api";
import { apiUrlV1 } from "../../../config/config";


export const consumablesProductGet = createAsyncThunk(
  'consumables/product-get',
  async (): Promise<Consumable[]> => {
    const res = await agent.get(`${apiUrlV1}/consumables/get/?is_in_list=True`);

    if (res.status === 200 || res.status === 201) {
      const result = await res.json();
      return result.results as Consumable[]
    }
    return [];
  }
);

export const consumablesGet = createAsyncThunk(
  'consumables/get',
  async (_, thunkApi): Promise<Consumable[]> => {
    const res = await agent.get(`${apiUrlV1}/consumables/get/`);

    if (res.status === 200 || res.status === 201) {
      const result = await res.json();
      thunkApi.dispatch(consumablesProductGet())
      return result.results as Consumable[]
    }
    return [];
  }
);

export type ConsumablesAddType = {
  name: string,
  price?: string,
  isInList?: boolean
}

export const consumablesAdd = createAsyncThunk(
  'consumables/add',
  async (body: ConsumablesAddType, thunkApi) => {
    const res = await agent.post(`${apiUrlV1}/consumables/create/`, {
      body: JSON.stringify(body),
    })

    if (res.status === 200 || res.status === 201) {
      if (body.isInList === false) {
        thunkApi.dispatch(consumablesGet())
      }
    } else {
      throw new Error('Cannot add a consumable');
    }
  }
)

type ConsumablesUpdateType = {
  id: number,
} & ConsumablesAddType

export const consumablesUpdate = createAsyncThunk(
  'consumables/update',
  async (body: ConsumablesUpdateType, thunkApi) => {
    const res = await agent.put(`${apiUrlV1}/consumables/update/${body.id}/`, {
      body: JSON.stringify(body),
    })

    if (res.status === 200 || res.status === 201) {
      thunkApi.dispatch(consumablesGet())
    } else {
      throw new Error('Cannot update a consumable');
    }
  }
)

export const consumablesDelete = createAsyncThunk(
  'consumables/delete',
  async (id: number, thunkApi) => {
    const res = await agent.delete(`${apiUrlV1}/consumables/delete/${id}/`)

    if (res.status === 200 || res.status === 201 || res.status === 204) {
      thunkApi.dispatch(consumablesGet())
    } else {
      throw new Error('Cannot delete a consumable');
    }
  }
)