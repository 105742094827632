import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {yupLocaleInit} from "./utils/validation";
import {languageLocalStorageKey, publicUrl} from "./config/config";

// translations are already at
// '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "ru", // set default language to Russian

    fallbackLng: "ru", // use en if detected lng is not available

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    backend: {
      loadPath: `${publicUrl}/locales/{{lng}}/{{ns}}.json`
    },

    detection: {
      lookupLocalStorage: languageLocalStorageKey
    }
  }, (err, t) => {
    if (err) {
      throw err;
    }

    yupLocaleInit(t);
  });

export default i18n;
