import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../../config/config";
import { agent, encodeGetParams } from "../../../api";
import { SalonInfoReq, SalonPoint, SalonServices, SalonWorker, WorkersAndEvents } from "src/entities/Salon";
import { Client } from "src/entities/Client";
import { fetchSalonProfile } from "../auth/profileLoad";
import { Service } from "src/entities/Specialization";
import { format, startOfMonth, endOfMonth } from "date-fns";
import { ScheduleFixed } from "src/entities/Schedule";
import { DayWorkload, WorkloadByDays } from "src/entities/Workload";

export const getSalonInfo = createAsyncThunk(
  'salon/salon-info',
  async () => {
    const res = await agent.get(`${apiUrlV1}/salon/salon-info/`);

    if (res.status === 200) {
      const body = await res.json();
      return body.results as SalonInfoReq
    }

    throw Error("Couldn't load salon list");
  }
)

export const getClientsByPointId = createAsyncThunk(
  'salon/clients',
  async (params: { pointId: number }, thunkApi): Promise<Client[]> => {
    const { pointId } = params;

    const res = await agent.get(
      `${apiUrlV1}/b2c/clients/`
      + ((pointId && `?salon_point=${pointId}`) || '')
    );

    if (res.status === 200) {
      const body = await res.json();
      return body.results as Client[];
    }

    return [];
  }
)

export const addClientByPointId = createAsyncThunk(
  'salon/addclient',
  async (client: Client, thunkApi): Promise<Client> => {
    const res = await agent.post(`${apiUrlV1}/b2c/clients/`, {
      body: JSON.stringify(client),
    });

    if (res.status === 201) {
      const body = await res.json();

      if (client.salonPoint) {
        thunkApi.dispatch(getClientsByPointId({ pointId: client.salonPoint }))
      }

      return body as Client;
    } else if (res.status === 400) {
      throw Error('Client cannot be added: phone already exists');
    }

    throw Error(`Client cannot be added: unknown reason`);
  }
)

export const updateClientByPointId = createAsyncThunk(
  'salon/updateclient',
  async (client: Client, thunkApi): Promise<Client> => {
    const res = await agent.put(`${apiUrlV1}/b2c/clients/${client.id}/`, {
      body: JSON.stringify(client),
    });

    if (res.status === 200) {
      const body = await res.json();

      if (client.salonPoint) {
        thunkApi.dispatch(getClientsByPointId({ pointId: client.salonPoint }))
      }

      return body as Client;
    }

    throw Error(`Client cannot be updated: unknown reason`);
  }
);

export const updateSalon = createAsyncThunk(
  'salon/update',
  async (params: SalonPoint, thunkApi) => {
    const res = await agent.put(`${apiUrlV1}/salon/salon-point/${params.id}/`, {
      body: JSON.stringify(params),
    })
    if (res.status === 200) {
      const body = await res.json();
      thunkApi.dispatch(fetchSalonProfile())
      return body as SalonPoint;
    }
  }
)

export const createSalon = createAsyncThunk(
  'salon/create',
  async (params: SalonPoint, thunkApi) => {
    const res = await agent.post(`${apiUrlV1}/salon/new-salon-point/`, {
      body: JSON.stringify(params),
    })
    if (res.status === 200) {
      const body = await res.json();
      thunkApi.dispatch(fetchSalonProfile())
      return body as SalonPoint;
    }
  }
)

export const deletePointSalon = createAsyncThunk(
  'salon/delete',
  async (id: number, thunkApi) => {
    const res = await agent.delete(`${apiUrlV1}/salon/salon-point/delete/${id}/`)
    if (res.status === 200 || res.status === 204) {
      thunkApi.dispatch(fetchSalonProfile())
    }
  }
)

export const getStaffByPointId = createAsyncThunk(
  'salon/workers',
  async (params: { pointId: number }): Promise<SalonWorker[]> => {
    const { pointId } = params;
    const res = await agent.get(
      `${apiUrlV1}/profiles/salon-worker/get/`
      + ((pointId && `?salon_point_id=${pointId}`) || '')
    );
    if (res.status === 200) {
      const body = await res.json();
      return body.results as SalonWorker[];
    }

    return [];
  }
)

type WorkerType = {
  salonId: number
  data: SalonWorker,
  systemSpecializationId: number[]
}

export const createWorkerBySalon = createAsyncThunk(
  'salon/create-worker',
  async (worker: WorkerType, thunkApi): Promise<SalonWorker> => {
    const res = await agent.post(`${apiUrlV1}/profiles/salon-worker/?system_specialization_id=${worker.systemSpecializationId}`, {
      body: JSON.stringify(worker.data),
    });
    if (res.status === 200) {
      const body = await res.json();

      thunkApi.dispatch(getStaffByPointId({ pointId: worker.salonId }))

      return body as SalonWorker;
    }

    throw Error(`Worker cannot be added: unknown reason`);
  }
)

export const updateScheduleWorker = createAsyncThunk(
  'salon/worker-schedule-set',
  async ({ schedule, pointId }: { schedule: ScheduleFixed, pointId: number }, thunkApi) => {
    const url = `${apiUrlV1}/schedule/salon-worker-${schedule.type}/${schedule.id || ''}/`;

    const res = await agent.put(url, {
      body: JSON.stringify({ days: schedule.days })
    });

    if (res.status === 201 || res.status === 200) {
      thunkApi.dispatch(getStaffByPointId({ pointId }))
      return schedule;
    }

    throw Error('Schedule not saved due some error!');
  }
)

export const deleteWorkerBySalon = createAsyncThunk(
  'salon/delete-worker',
  async ({ workerId, pointId }: { workerId: number, pointId: number }, thunkApi): Promise<SalonWorker> => {
    const res = await agent.delete(`${apiUrlV1}/profiles/salon-worker/delete/${workerId}/`);
    if (res.status === 200) {
      const body = await res.json();

      thunkApi.dispatch(getStaffByPointId({ pointId }))

      return body as SalonWorker;
    }

    throw Error(`Worker cannot be added: unknown reason`);
  }
)

export const updateWorkerBySalon = createAsyncThunk(
  'salon/update-worker',
  async (worker: WorkerType, thunkApi): Promise<SalonWorker> => {
    const res = await agent.put(`${apiUrlV1}/profiles/salon-worker/update/${worker.data.id}/?system_specialization_id=${worker.systemSpecializationId}`, {
      body: JSON.stringify(worker.data),
    });
    if (res.status === 200) {
      const body = await res.json();

      thunkApi.dispatch(getStaffByPointId({ pointId: worker.salonId }))

      return body as SalonWorker;
    }

    throw Error(`Worker cannot be added: unknown reason`);
  }
)

export const getServicesSalon = createAsyncThunk(
  'salon/services',
  async (): Promise<SalonServices[]> => {
    const res = await agent.get(`${apiUrlV1}/salon/salon-services/get/`)

    if (res.status === 200) {
      const body = await res.json()
      return body.results as SalonServices[]
    }

    return []
  }
)

type ServiceReturn = {
  service: Service[],
  // staff: SalonWorker[]
}

export const loadSystemServices = createAsyncThunk(
  'salon/system/services',
  async ({ systemSpecializationId }: { systemSpecializationId?: number }): Promise<ServiceReturn> => {
    const servicesParams = encodeGetParams({
      system_specialization: `${systemSpecializationId}`,
    });

    const res = await agent.get(`${apiUrlV1}/catalog/system/services/${servicesParams}`)

    // const resStaff = await agent.get(`${apiUrlV1}/profiles/salon-worker/get/`)

    if (res.status === 200) {
      const bodyService = await res.json()
      // const bodyStaff = await resStaff.json()
      return {
        service: bodyService.results as Service[],
        // staff: bodyStaff.results as SalonWorker[]
      }
    }

    return {
      service: [],
      // staff: []
    }
  }
)

type CreateServiceType = {
  id?: number,
  title: string,
  system_service: number,
  price: number,
  duration: string,
  places: string[],
  is_active: boolean,
  description: string,
  salon_points: number[],
  workers: number[],
  images_ids: number[]
}

export const createServicesSalon = createAsyncThunk(
  'salon/create-service',
  async (body: CreateServiceType, thunkApi): Promise<SalonServices> => {
    const res = await agent.post(`${apiUrlV1}/salon/salon-services/`, {
      body: JSON.stringify(body),
    });

    if (res.status === 200 || res.status === 201) {
      const body = await res.json()

      thunkApi.dispatch(getServicesSalon())

      return body as SalonServices;
    }

    throw Error(`Worker cannot be added: unknown reason`);
  }
)

export const deleteServiceSalon = createAsyncThunk(
  'salon/delete-service',
  async (id: number, thunkApi) => {
    const res = await agent.delete(`${apiUrlV1}/salon/salon-services/${id}/`)
    if (res.status === 200 || res.status === 204) {
      thunkApi.dispatch(getServicesSalon())
    }
  }
)

interface UpdateSalonServices {
  id: number,
  title: string,
  workersIds: number[],
  salonPointsIds: number[],
  imagesIds: number[],
  description: string,
  price: string,
  duration: string,
  systemService: number,
  systemSpecialization: number,
}

export const updateServiceSalon = createAsyncThunk(
  'salon/update-service',
  async (body: UpdateSalonServices, thunkApi): Promise<SalonServices> => {
    const res = await agent.put(`${apiUrlV1}/salon/salon-services/update/${body.id}/`, {
      body: JSON.stringify(body),
    });

    if (res.status === 200 || res.status === 201) {
      const body = await res.json()

      thunkApi.dispatch(getServicesSalon())

      return body as SalonServices;
    }

    throw Error(`Worker cannot be added: unknown reason`);
  }
)

export const getWorkersBySpecialization = createAsyncThunk(
  'salon/dashboard/get-workers',
  async ({ specializationId, salon_point_id }: { specializationId: number, salon_point_id: number[] }): Promise<SalonWorker[]> => {
    const res = await agent.get(`${apiUrlV1}/profiles/salon-worker/get/`
      + `?system_specialization_id=${specializationId}`
      + `&salon_point_id=${salon_point_id.join()}`)

    if (res.status === 200) {
      const body = await res.json()
      return body.results as SalonWorker[]
    }
    return []
  }
)

//Dashboard
export const workLoadStat = createAsyncThunk(
  'salon/workload-statistics',
  async ({ salon_point_id, date }: { salon_point_id: number, date: Date }) => {
    const params = encodeGetParams({
      start_date: format(startOfMonth(date), 'yyyy-MM-dd'),
      end_date: format(endOfMonth(date), 'yyyy-MM-dd'),
      salon_point_id
    });
    const res = await agent.get(`${apiUrlV1}/salon/point/workload-statistics/` + params)
    if (res.status === 200) {
      const body = await res.json()

      if (!body) {
        throw Error('No workload!');
      }

      return body as DayWorkload[]
    }

    throw Error('No workload loaded!');
  }
)

type WorkerEventsParams = {
  salon_point_id: number
  start_datetime: string,
  end_datetime: string
}
export const getWorkersEvents = createAsyncThunk(
  'salon/dashboard/point-events',
  async (params: WorkerEventsParams): Promise<WorkersAndEvents[]> => {
    const getParams = encodeGetParams(params);
    const res = await agent.get(`${apiUrlV1}/profiles/salon-worker-events/` + getParams)

    if (res.status === 200) {
      const body = await res.json()
      return body as WorkersAndEvents[]
    }

    return []
  }
)

export const getServicesPoint = createAsyncThunk(
  'salon/dashboard/point-services',
  async ({ pointId, search }: { pointId: number, search: string }): Promise<SalonServices[]> => {
    const res = await agent.get(`${apiUrlV1}/salon/salon-services/get/?search=${search}&salon_point_id=${pointId}` +
      (search.length === 0 ? '&limit=4' : ''))

    if (res.status === 200) {
      const body = await res.json()
      return body.results as SalonServices[]
    }

    return []
  }
)

export const getWorkersByServices = createAsyncThunk(
  'salon/dashboard/get-workers',
  async ({ services, pointId }: { services: number[], pointId: number }): Promise<SalonWorker[]> => {
    const res = await agent.get(`${apiUrlV1}/profiles/salon-worker/get/` +
      `?salon_point_id=${pointId}` + `&salon_services_ids=${services.join()}`)

    if (res.status === 200) {
      const body = await res.json()
      return body.results as SalonWorker[]
    }
    return []
  }
)
type SalonServiceEvent = {
  start_datetime: string,
  end_datetime: string,
  comment: string,
  client_id: number,
  salon_services_ids: number[],
  worker: number
}

export const createSalonOrder = createAsyncThunk(
  'salon/dashboard/order-create',
  async ({ body, date, salonId }: { body: SalonServiceEvent, date: string, salonId: number }, thunkApi): Promise<any> => {
    const res = await agent.post(`${apiUrlV1}/b2c/salon-events/`, {
      body: JSON.stringify(body)
    })

    if (res.status === 200 || res.status === 201) {
      const body = await res.json()
      thunkApi.dispatch(getWorkersEvents({
        salon_point_id: salonId,
        start_datetime: `${date}T00:00:00`,
        end_datetime: `${date}T23:55:00`
      }))
      return body.results
    }

    return []
  }
)

export const updateSalonOrder = createAsyncThunk(
  'salon/dashboard/order-update',
  async ({ body, date, salonId }: { body: any, date: string, salonId: number }, thunkApi): Promise<any> => {
    const res = await agent.put(`${apiUrlV1}/b2c/salon-events/${body.id}`, {
      body: JSON.stringify(body)
    })

    if (res.status === 200 || res.status === 201) {
      const body = await res.json()
      thunkApi.dispatch(getWorkersEvents({
        salon_point_id: salonId,
        start_datetime: `${date}T00:00:00`,
        end_datetime: `${date}T23:55:00`
      }))
      return body.results
    }
    return []
  }
)

export const deleteSalonOrder = createAsyncThunk(
  'salon/dashboard/order-delete',
  async ({ id, date, salonId }: { id: number, date: string, salonId: number }, thunkApi): Promise<any> => {
    const res = await agent.delete(`${apiUrlV1}/b2c/salon-events/${id}`)

    if (res.status === 200 || res.status === 204) {
      thunkApi.dispatch(getWorkersEvents({
        salon_point_id: salonId,
        start_datetime: `${date}T00:00:00`,
        end_datetime: `${date}T23:55:00`
      }))
    }
  }
)