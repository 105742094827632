import React from 'react'
import { Select, SelectProps, makeStyles } from '@material-ui/core'
import { ReactComponent as IconBottom } from '../../icons/icon-arrowBottom.svg'
import { Select as MUISelect } from "mui-rff"

import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    height: 46,
    '& .Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.38)'
    }
  },
})

export const B2CSelect = (props: SelectProps) => {
  const classes = useStyles();

  return <Select
    name={props.name || 'name'}
    {...props}
    className={clsx(classes.root, props.className)}
    classes={{
      root: props.classes?.root
    }}
    IconComponent={IconBottom} />
}

export const MUIB2CSelect = (props: SelectProps) => {
  const classes = useStyles();

  return <MUISelect
    name={props.name || 'name'}
    {...props}
    className={clsx(classes.root, props.className)}
    classes={{
      root: props.classes?.root
    }}
    IconComponent={IconBottom} />
}